import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserSchema, userSchema } from "../../../schemas";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { getAllLocations } from "../../../redux/location/actions";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import {
  addUser,
  getUserWithId,
  updateUser,
} from "../../../redux/auth/actions";
import Breadcrumb from "../../../components/Breadcrumb";

const roles = [
  { title: "Select Role" },
  { title: "Doctor", value: "DOCTOR" },
  { title: "Super Admin", value: "SUPER_ADMIN" },
  { title: "Non clinical admin", value: "CLINICAL_ASSISTANT" },
  { title: "Reception Manager", value: "RECEPTION_MANAGER" },
  {title: "Clinical Head", value: "CLINICAL_HEAD"},
  { title: "Admin", value: "CLINICAL_ADMIN" },
  { title: "Plan Maker", value: "PLAN_MAKER" },
  { title: "Therapist", value: "THERAPIST" },
];

const AddUser = () => {
  const pages = [{ title: "Users", href: "/users" }];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: userId } = useParams();
  const {
    auth: { loading, userData },
    location: { list: locationList },
  } = useSelector((state) => state);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirm_password: "",
    locationId: "",
    role: "",
  };

  const [formState, setFormState] = useState(initialValues);

  useEffect(() => {
    console.log(locationList);
    if (userId) dispatch(getUserWithId(userId));
    if (!locationList.length) {
      dispatch(getAllLocations());
    }
  }, [userId, locationList]);

  useEffect(() => {
    document.title = "New Horizon | Add-User";
    if (userId && userData) {
      const newFormState = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        locationId: userData.locationId,
        role: userData.role,
      };
      setFormState(newFormState);
    }
  }, [userId, userData]);

  const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: userId ? UpdateUserSchema : userSchema,
      onSubmit: (values, action) => {
        console.log(values);
        const body = { ...values };
        body.locationId = parseInt(values.locationId);
        delete body.confirm_password;
        if (!userId) dispatch(addUser(body));
        else {
          if(!body.password) delete body.password;
          dispatch(updateUser(userId, body));
        }
        action.resetForm();
        navigate("/users");
      },
    });

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <button
        type="button"
        onClick={() => navigate(-1)}
        className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        <span className="w-5 mr-2">
          <ArrowUturnLeftIcon size={18} />
        </span>{" "}
        Back
      </button>
      <div className="space-y-6 sm:space-y-5">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-lg font-medium leading-6 text-gray-900">
              {userId ? "Update User" : "Add User"}
            </h1>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              This information will be displayed publicly so be careful what you
              share.
            </p>
          </div>
        </div>
        {loading ? (
          <FallingLinesLoader />
        ) : (
          <form
            onSubmit={handleSubmit}
            className="space-y-6 divide-y divide-gray-200"
          >
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  First Name
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="First Name"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.firstName && touched.firstName ? (
                    <p className="text-red-600 text-sm">{errors.firstName}</p>
                  ) : null}
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Last name
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    id="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.lastName && touched.lastName ? (
                    <p className="text-red-600 text-sm">{errors.lastName}</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Email Address
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  type="email"
                  placeholder="Email Address"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <p className="text-red-600 text-sm">{errors.email}</p>
                ) : null}
              </div>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Password
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    placeholder="Password"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    type="Password"
                    name="password"
                    id="phone"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.password && touched.password ? (
                    <p className="text-red-600 text-sm">{errors.password}</p>
                  ) : null}
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="confirm_password"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Confirm Password
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    type="password"
                    placeholder="Confirm Password"
                    name="confirm_password"
                    id="confirm_password"
                    autoComplete="off"
                    value={values.confirm_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  {errors.confirm_password && touched.confirm_password ? (
                    <p className="text-red-600 text-sm">
                      {errors.confirm_password}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="locationId"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Location ID
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <select
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    name="locationId"
                    id="locationId"
                    value={values.locationId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option> Select Location </option>
                    {locationList.map((location, i) => (
                      <option key={i} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                  </select>

                  {errors.locationId && touched.locationId ? (
                    <div className="text-sm text-red-600">
                      {errors.locationId}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Role
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  {console.log(errors)}
                  <select
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    name="role"
                    id="role"
                    value={values.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option> Select a Role </option>
                    {roles.map((role, i) => (
                      <option key={i} value={role.value}>
                        {role.title}
                      </option>
                    ))}
                  </select>

                  {errors.role && touched.role ? (
                    <div className="text-sm text-red-600">{errors.role}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="submit"
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddUser;
