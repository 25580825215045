import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import RcCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  CheckIcon,
  ChevronUpDownIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import { Menu, Transition, Listbox, Dialog } from "@headlessui/react";
import { Modal } from "@mui/material";
import Breadcrumb from "../../../components/Breadcrumb";
import moment from "moment";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addAppointment,
  editAppointment,
  getAppointment,
  getAppointmentByDoctor,
  getAvailableSlots,
  deleteAppointment,
} from "../../../redux/appointment/actions";
import { getAllLocations } from "../../../redux/location/actions";
import { getAllDoctors } from "../../../redux/doctor/actions";
import {
  addPatient,
  getAllPatients,
  updatePatient,
} from "../../../redux/patient/actions";
import { Formik, useFormik } from "formik";
import axios from "axios";
import { getHeaders } from "../../../redux/auth/actions";
import * as dateMath from "date-arithmetic";
import {
  AppointmentSchema,
  patientsSchema,
  UpdateAppointmentSchema,
} from "../../../schemas";
import styled from "styled-components";
import PaidOutlined from "@mui/icons-material/PaidOutlined";
import MoneyOffCsredOutlined from "@mui/icons-material/MoneyOffCsredOutlined";
import { AiOutlinePlus } from "react-icons/ai";
import { getAllReferral } from "../../../redux/referral/action";
import { AddRefferalModal } from "../Patients/Addpatients";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import app_api from "../../../utilities/api";
import { useAsyncDebounce } from "react-table";
import { FiLoader } from "react-icons/fi";
const mLocalizer = momentLocalizer(moment);
const options = [
  { name: "Swedish", value: "sv" },
  { name: "English", value: "en" },
  {
    type: "group",
    name: "Group name",
    items: [{ name: "Spanish", value: "es" }],
  },
];
const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      // backgroundColor: "lightblue",
      minHeight: "50px",
    },
    className:
      "group inset-1 flex flex-col overflow-y-auto p-2 text-xs leading-5",
  });

const CalendarDiv = styled.div`
  & .rbc-month-row {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;

const API = process.env.REACT_APP_API_URL;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const initialValues = {
  type: "",
  state: false,
  editId: null,
  data: {
    type: "OFFLINE",
    locationId: "",
    patientId: "",
    doctorId: "",
    time_start_time: "",
    time_end_time: "",
    date: "",
    time: "",
    payment_type: "FULL",
    remark: "",
    cost: 1000,
    isFollowUp: false,
    advance_payment_amount: "",
    book_type: false,
    enrolled: false,
  },
};

export function extractIdAndName(inputArray) {
  const outputArray = [];
  for (let i = 0; i < inputArray.length; i++) {
    const obj = {};
    const pid = inputArray[i].patient_id ? inputArray[i].patient_id : "N/A";
    obj.value = inputArray[i].id;
    obj.label = ` ${inputArray[i].name
      ? inputArray[i].name
      : inputArray[i].firstName +
      " " +
      inputArray[i].lastName +
      " " +
      "-" +
      " " +
      pid
      } `;
    outputArray.push(obj);
  }
  return outputArray;
}

export default function Appointment({ localizer = mLocalizer, ...props }) {
  const pages = [{ title: "Appointment", href: "/appointment" }];
  // const container = useRef(null);
  // const containerNav = useRef(null);
  // const containerOffset = useRef(null);
  const [selected, setSelected] = useState({
    id: 0,
    first_name: "Filter by Doctor",
  });
  const [status, setStatus] = useState({
    id: 0,
    status: "Filter by status",
  });
  const [filterLoc, setFilterLoc] = useState({
    id: 0,
    name: "Filter by location",
  });
  const [selectedPatient, setselectedPatient] = useState({});
  const closeModal = () => {
    setModalData((prev) => ({
      ...prev,
      state: false,
      type: "",
      data: initialValues.data,
    }));
    setAvailDates([]);
    setAvailTimes([]);
  };

  const [allPatients, setAllPatients] = useState({
    data: [],
    loading: true,
    error: null,
    query: "",
  });

  useEffect(() => {
    setAllPatients((prev) => ({ ...prev, loading: true }));
    app_api.get(`/api/patient/search?limit=20${allPatients.query ? `&query=${allPatients.query}` : ""}`).then((res) => {
      setAllPatients(prev => ({ ...prev, data: res.data.data, loading: false }));
    });
  }, [allPatients.query]);

  const onFilterChange = useAsyncDebounce((value) => {
    setAllPatients(prev => ({ ...prev, query: value }));
  }, 200);

  const [modalData, setModalData] = useState(initialValues);

  const setOpen = () => {
    setModalData((prev) => ({
      ...prev,
      state: true,
      type: "add",
      data: initialValues.data,
    }));
  };

  const setOpenAvailableSlot = (doctorId, cost, date, time) => {
    getAvailableDates(doctorId);
    getAvailableTimes(doctorId, date, "OFFLINE");
    setModalData((prev) => ({
      ...prev,
      state: true,
      type: "add",
      data: {
        ...prev.data,
        locationId: filterLoc.id !== 0 ? filterLoc.id : "",
        doctorId: doctorId.toString(),
        date: new Date(moment(date).format("YYYY-MM-DD")),
        cost,
        time,
      },
    }));
  };

  const [addPatientModal, setaddPatientModal] = useState(false);

  const editOpen = (id) => {
    let a = appointment.list.find((a) => a.id === id);
    getAvailableDates(a.doctorId);
    getAvailableTimes(a.doctorId, moment(a.date).format("YYYY-MM-DD"), a.type);
    setModalData((prev) => ({
      ...prev,
      state: true,
      type: "edit",
      data: a,
    }));
  };

  const editClose = () => {
    setModalData((prev) => ({
      ...prev,
      state: false,
      type: "",
      data: initialValues.data,
    }));
    setAvailDates([]);
    setAvailTimes([]);
    setCommentModal({ state: false, id: null });
  };

  const [availDates, setAvailDates] = useState([]);

  const getAvailableDates = (doctorId, appointment) => {
    try {
      let url = `${API}/api/appointment/search/dates/${doctorId}`;
      if (appointment)
        url = `${API}/api/appointment/search/dates/${doctorId}/${appointment}`;
      axios.get(url, { headers }).then((res) => {
        setAvailDates(res.data.data);
      }).catch((err) => {
        console.log(err);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [availTimes, setAvailTimes] = useState([]);
  const getAvailableTimes = (doctorId, date, type) => {
    axios
      .post(
        `${API}/api/appointment/search/date/time-slots`,
        {
          doctor_id: doctorId,
          date: date,
          appointment_type: type,
        },
        { headers }
      )
      .then((res) => {
        setAvailTimes(res.data.data || []);
      });
  };

  const [rangeDates, setRangeDates] = useState({
    start: dateMath.startOf(new Date(), "month"),
    end: dateMath.endOf(new Date(), "month"),
  });

  const dispatch = useDispatch();
  const {
    appointment: appointment,
    exception: exception,
    location: location,
    patient: patient,
    doctor: doctor,
  } = useSelector((state) => state);

  const cancelButtonRef = useRef(null);
  const cancelCancleButtonRef = useRef(null);
  const [headers, setHeaders] = useState("");

  const [showAvailableSlots, setShowAvailableSlots] = useState(false);

  useEffect(() => {
    dispatch(getAppointment());
    dispatch(getAvailableSlots());
    dispatch(getAllDoctors());
    dispatch(getAllLocations());
    dispatch(getAllPatients());
    setHeaders(dispatch(getHeaders()));
  }, []);

  useEffect(() => {
    dispatch(getAllPatients());
  }, [addPatientModal]);

  const { components, defaultDate, views, min, max } = useMemo(() => {
    let today = new Date();
    return {
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
      },
      defaultDate: new Date(),
      views: [Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA],
      min: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9),
      max: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 21),
    };
  }, []);

  const eventPropGetter = useCallback((event, start, end, isSelected) => {
    let isConfirmed = event.status === "CONFIRMED";
    return {
      ...(event.isFollowUp &&
        isConfirmed && {
        style: {
          backgroundColor: "orange",
          color: "black",
          border: "2px solid green",
        },
      }),
      ...(event.isFollowUp &&
        !isConfirmed && {
        style: {
          backgroundColor: "orange",
          color: "black",
          border: "2px solid orange",
        },
      }),
      ...(!event.isFollowUp &&
        isConfirmed && {
        style: {
          backgroundColor: "green",
          color: "white",
          border: "2px solid green",
        },
      }),
      ...(!(event.isFollowUp || isConfirmed) && {
        style: {
          backgroundColor: "green",
          color: "white",
          border: "2px solid orange",
        },
      }),
      ...(event.isBackground && {
        style: {
          backgroundColor: "",
          color: "",
        },
      }),
    };
  }, []);

  const selectStatus = (e) => {
    const _status = e.id === 0 || e.id === 1 ? "OFFLINE" : "ONLINE";
    const _doc = selected.id === 0 ? null : selected.id;
    dispatch(
      getAvailableSlots(rangeDates.start, rangeDates.end, _doc, _status)
    );
    if (e.status === "ONLINE") {
      const _enable = location.list.find((d) => d.name.trim() === "E-nable");
      if (_enable) {
        setFilterLoc({
          id: _enable.id,
          name: _enable.name,
        });
      }
    }
    setStatus(e);

  };

  const selectLocation = (e) => {
    setFilterLoc(e);
  };

  const selectDoctor = (e) => {
    // console.log('on change', e);
    setSelected(e);
    if (e.id !== 0) {
      // make API call with doctorID
      dispatch(getAppointmentByDoctor(rangeDates.start, rangeDates.end, e.id));
      const _status = status.id === 0 || status.id === 1 ? "OFFLINE" : "ONLINE";
      dispatch(
        getAvailableSlots(rangeDates.start, rangeDates.end, e.id, _status)
      );
      setShowAvailableSlots(true);
    } else {
      // make API call with no doctorID
      dispatch(getAppointment(rangeDates.start, rangeDates.end));
      setShowAvailableSlots(false);
    }
  };

  const initModalState = {
    id: "",
    state: false,
    title: "Add Comment",
    button: "Add",
    data: {
      cancel_reason: "",
    },
  };
  const [CommentModal, setCommentModal] = useState(initModalState);
  // const cancleRModal = () => {
  //   const { state, id, title, button } = CommentModal;
  //   return (

  //   );
  // };
  const deleteApp = (id) => {
    setCommentModal((prev) => ({ ...prev, state: true, id: id }));
  };
  // const [docenable, setdocenable] = useState(true); // for enabling and disabling data and time
  // const [timeenable, settimeenable] = useState(true); // for enabling and disabling data and time

  return (
    <div className="flex  flex-col" style={{ height: "85vh" }}>
      <Breadcrumb pages={pages} />
      <header className="flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6">
        <h1 className="text-lg font-semibold text-gray-900">Appointments</h1>
        <div className="flex items-center">
          <Listbox value={status} onChange={selectStatus}>
            {({ open }) => (
              <>
                {/* <Listbox.Label className="pl-6 pr-2 block text-sm font-medium text-gray-700">Select Doctor</Listbox.Label> */}
                <div className="relative  pl-4">
                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3  text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span className="block truncate w-32 font-medium">
                      {status.status}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-indigo-600"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-8 pr-4"
                          )
                        }
                        value={{
                          id: 0,
                          status: "Filter by status",
                        }}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              Filter by status
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-indigo-600"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-8 pr-4"
                          )
                        }
                        value={{
                          id: 1,
                          status: "OFFLINE",
                        }}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              Offline
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-indigo-600"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-8 pr-4"
                          )
                        }
                        value={{
                          id: 2,
                          status: "ONLINE",
                        }}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              Online
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          <Listbox value={filterLoc} onChange={selectLocation}>
            {({ open }) => (
              <>
                {/* <Listbox.Label className="pl-6 pr-2 block text-sm font-medium text-gray-700">Select Doctor</Listbox.Label> */}
                <div className="relative  pl-4">
                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3  text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span className="block truncate w-40 font-medium">
                      {filterLoc.name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-indigo-600"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-8 pr-4"
                          )
                        }
                        value={{
                          id: 0,
                          name: "Filter by location",
                        }}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              Filter by location
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                      {location.list.map((l, idx) => (
                        <Listbox.Option
                          key={idx}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-8 pr-4"
                            )
                          }
                          value={{
                            id: l.id,
                            name: l.name,
                          }}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {l.name}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          <Listbox value={selected} onChange={selectDoctor}>
            {({ open }) => (
              <>
                {/* <Listbox.Label className="pl-6 pr-2 block text-sm font-medium text-gray-700">Select Doctor</Listbox.Label> */}
                <div className="relative pl-4">
                  <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                    <span className="block truncate w-32 font-medium">
                      {selected.first_name}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-indigo-600"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-8 pr-4"
                          )
                        }
                        value={{
                          id: 0,
                          first_name: "Filter by doctor",
                        }}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              Filter by doctor
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                      {doctor.list.map((d, idx) => (
                        <Listbox.Option
                          key={idx}
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-8 pr-4"
                            )
                          }
                          value={{
                            id: d.id,
                            first_name: `Dr. ${d.first_name} ${d.last_name}`,
                          }}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {d.first_name + " " + d.last_name}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          <button
            type="button"
            onClick={() => setOpen(true)}
            className="ml-6 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Add Appointment
          </button>
        </div>
      </header>
      <CalendarDiv className="my-2 min-h-[100%]">
        {/* {appointment.loading ? null : ( */}
        {/* // : appointment.error !== null ? null */}
        <Calendar
          components={components}
          defaultDate={defaultDate}
          events={
            appointment.loading
              ? []
              : appointment.error !== null
              ? []
              : appointment.list
                  .filter((a) => {
                    if (status.id !== 0) {
                      return a.type === status.status;
                    }
                    return true;
                  })
                  .filter((a) => {
                    if (filterLoc.id !== 0) {
                      return a.locationId === filterLoc.id;
                    }
                    return true;
                  })
                  .map((a) => {
                    return {
                      id: a.id,
                      title: [
                        a.status === "CONFIRMED" ? (
                          a.payment_type === "FULL" ? (
                            <PaidOutlined fontSize="small" />
                          ) : (
                            <MoneyOffCsredOutlined fontSize="small" />
                          )
                        ) : null,
                        ` Dr. ${a.doctor?.first_name}-${a.patient?.firstName}`,
                      ],
                      start: new Date(
                        `${moment(a.date)?.format("YYYY-MM-DD")}T${moment(
                          a.start_time?.split("T")[1],
                          "HH:mm:ss"
                        ).format("HH:mm")}:00+05:30`
                      ),
                      end: new Date(
                        `${moment(a.date)?.format("YYYY-MM-DD")}T${moment(
                          a.end_time?.split("T")[1],
                          "HH:mm:ss"
                        ).format("HH:mm")}:00+05:30`
                      ),
                      status: a.status,
                      isFollowUp: a.isFollowUp,
                    };
                  })
          }
          eventPropGetter={eventPropGetter}
          backgroundEvents={
            !showAvailableSlots
              ? []
              : appointment.aLoading
              ? []
              : appointment.aError !== null
              ? []
              : appointment.availableList
                  .filter((a) => {
                    if (filterLoc.id !== 0)
                      return a.location === filterLoc.name;
                    return true;
                  })
                  .map((a) => ({
                    start: `${a.date}T${a.start_time}:00+05:30`,
                    end: `${a.date}T${a.end_time}:00+05:30`,
                    title: `Dr. ${a.doctor.first_name} ${a.doctor.last_name}`,
                    allDay: false,
                    isBackground: true,
                    doctorId: a.doctorId,
                    cost: a.doctor.appointment_cost,
                    date: a.date,
                    time: `${a.start_time}-${a.end_time}`,
                  }))
          }
          min={min}
          max={max}
          localizer={localizer}
          showMultiDayTimes
          step={20}
          views={views}
          // dayLayoutAlgorithm="no-overlap"
          dayPropGetter={() => ({
            className: "text-xs leading-5 border-transparent",
          })}
          defaultView={Views.MONTH}
          drilldownView="agenda"
          onDoubleClickEvent={(event) => {
            event.isBackground
              ? setOpenAvailableSlot(
                  event.doctorId,
                  event.cost,
                  event.date,
                  event.time
                )
              : editOpen(event.id);
          }}
          onRangeChange={async (range) => {
            let start, end;
            if ("start" in range) {
              start = range["start"];
              end = range["end"];
            } else {
              start = range[0];
              if (range.length > 1) {
                end = range[range.length - 1];
              } else {
                end = start;
              }
            }
            setRangeDates({ start, end });
            if (selected.id !== 0) {
              dispatch(getAppointmentByDoctor(start, end, selected.id));
              dispatch(
                getAvailableSlots(start, end, selected.id, status.status)
              );
              setShowAvailableSlots(true);
            } else {
              dispatch(getAppointment(start, end));
              setShowAvailableSlots(false);
            }
          }}
          showAllEvents
        />
        {/* )} */}
      </CalendarDiv>
      {/* // ----------------Add Appointment Modal--------------------- */}
      <Transition.Root
        show={modalData.type === "add" && modalData.state}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <AddPatientModal
              setaddPatientModal={setaddPatientModal}
              addPatientModal={addPatientModal}
            />
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start ">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Add Appointment
                      </Dialog.Title>
                      <div className="mt-2">
                        <Formik
                          onSubmit={(values) => {
                            let start = values.time.split("-")[0];
                            let end = values.time.split("-")[1];
                            dispatch(
                              addAppointment({
                                type: values.type,
                                locationId: parseInt(values.locationId),
                                patientId: parseInt(values.patientId),
                                doctorId: parseInt(values.doctorId),
                                date: moment(values.date).format("YYYY-MM-DD"),
                                start_time: start,
                                end_time: end,
                                payment_type:
                                  values.payment_type !== "FULL"
                                    ? values.advance_payment_amount ===
                                      values.cost
                                      ? "FULL"
                                      : "ADVANCE"
                                    : "FULL",
                                cost: values.cost,
                                is_enrolled: values.enrolled,
                                remark: values.remark,
                                discount: Number(values.discount),
                                isFollowUp: values.isFollowUp,
                                status: "TENTATIVE",
                                advance_payment_amount:
                                  values.advance_payment_amount
                                    ? values.advance_payment_amount
                                    : null,
                                book_type: values.book_type
                                  ? "CUSTOM"
                                  : "NORMAL",
                              })
                            );
                            closeModal();
                          }}
                          initialValues={modalData.data}
                          validationSchema={AppointmentSchema}
                        >
                          {({
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setValues,
                            values,
                            touched,
                            isValid,
                            isSubmitting,
                            errors,
                            setFieldValue,
                          }) => (
                            <form
                              noValidate
                              onSubmit={handleSubmit}
                              className="space-y-8 divide-y divide-gray-200"
                            >
                              {/* {console.log(errors)} */}
                              <div className="space-y-8 divide-y divide-gray-200">
                                <div>
                                  <div>
                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="type"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Type
                                      </label>
                                      <div className="mt-1">
                                        <select
                                          value={values.type}
                                          onChange={(e) => {
                                            handleChange(e);
                                            getAvailableTimes(
                                              parseInt(values.doctorId),
                                              moment(values.date).format(
                                                "YYYY-MM-DD"
                                              ),
                                              e.target.value
                                            );
                                            setValues({
                                              ...values,
                                              type: e.target.value,
                                            });
                                          }}
                                          onBlur={handleBlur}
                                          name="type"
                                          id="type"
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        >
                                          <option value={null}>
                                            Select a type
                                          </option>
                                          <option value="OFFLINE">
                                            Offline
                                          </option>
                                          <option value="ONLINE">Online</option>
                                        </select>
                                      </div>
                                    </div>
                                    {errors.type && touched.type ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.type}
                                      </p>
                                    ) : null}
                                    <div className="relative flex items-start mt-4">
                                      <div className="flex h-5 items-center">
                                        <input
                                          checked={values.isFollowUp}
                                          onChange={(e) => {
                                            let thisdoctor = doctor.list.find(
                                              (d) => d.id == values.doctorId
                                            );
                                            setValues({
                                              ...values,
                                              isFollowUp: e.target.checked,
                                              cost: e.target.checked
                                                ? thisdoctor?.is_follow_up_cost ||
                                                  0
                                                : thisdoctor?.appointment_cost ||
                                                  0,
                                            });
                                            handleChange(e);
                                          }}
                                          onBlur={handleBlur}
                                          name="isFollowUp"
                                          type="checkbox"
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                      </div>
                                      <div className="ml-3 text-sm">
                                        <label
                                          htmlFor="isFollowUp"
                                          className="font-medium text-gray-700"
                                        >
                                          Is Follow Up
                                        </label>
                                      </div>
                                    </div>
                                    {errors.isFollowUp && touched.isFollowUp ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.isFollowUp}
                                      </p>
                                    ) : null}
                                    {/* location start */}
                                    {/* {values.type === "OFFLINE" && ( */}
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="locationid"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Location
                                      </label>
                                      <div className="mt-1">
                                        {location.loading ? (
                                          <span className="text-sm text-indigo-700">
                                            Fetching locations
                                          </span>
                                        ) : location.error !== null ? (
                                          <span className="text-sm text-red-700">
                                            {location.error}
                                          </span>
                                        ) : (
                                          <select
                                            id="locationId"
                                            name="locationId"
                                            value={values.locationId}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                          >
                                            <option>Select a location</option>
                                            {location.list.map((l, index) => (
                                              <option key={index} value={l.id}>
                                                {l.name}
                                              </option>
                                            ))}
                                          </select>
                                        )}
                                      </div>
                                    </div>

                                    {/* Location end */}

                                    {values.type === "OFFLINE" &&
                                    errors.locationId &&
                                    touched.locationId ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.locationId}
                                      </p>
                                    ) : null}

                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="patientId"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Patient
                                      </label>
                                      <div className="mt-1 flex">
                                        {patient.loading ? (
                                          <span className="text-sm text-indigo-700">
                                            Fetching patients
                                          </span>
                                        ) : patient.error !== null ? (
                                          <span className="text-sm text-red-700">
                                            {patient.error}
                                          </span>
                                        ) : (
                                          <Select
                                            options={extractIdAndName(
                                              allPatients.data
                                            )}
                                            name="patientId"
                                            onInputChange={(e) => {
                                              onFilterChange(e);
                                            }}
                                            onChange={(value, e) => {
                                              setValues({
                                                ...values,
                                                patientId: value.value,
                                              });
                                            }}
                                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                                          />
                                        )}
                                        {allPatients.loading && (
                                          <span className="inline-block text-indigo-500">
                                            <FiLoader
                                              className="animate-spin"
                                              size={24}
                                            />
                                          </span>
                                        )}

                                        {patient.loading ? null : (
                                          <button
                                            type="button"
                                            onClick={() => {
                                              setaddPatientModal(true);
                                            }}
                                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 items-center "
                                          >
                                            <AiOutlinePlus size={23} />
                                          </button>
                                        )}
                                      </div>
                                      <div className="relative flex items-start mt-4">
                                        <div className="flex h-5 items-center ml-4">
                                          <input
                                            onBlur={handleBlur}
                                            name="enrolled"
                                            onChange={handleChange}
                                            checked={values.enrolled}
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                          />
                                        </div>

                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor="book_type"
                                            className="font-medium text-gray-700"
                                          >
                                            Already Enrolled
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    {errors.patientId && touched.patientId ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.patientId}
                                      </p>
                                    ) : null}
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="doctorId"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Doctor
                                      </label>
                                      <div className="mt-1">
                                        {doctor.loading ? (
                                          <span className="text-sm text-indigo-700">
                                            Fetching doctors
                                          </span>
                                        ) : doctor.error !== null ? (
                                          <span className="text-sm text-red-700">
                                            {doctor.error}
                                          </span>
                                        ) : (
                                          <select
                                            id="doctorId"
                                            name="doctorId"
                                            value={values.doctorId}
                                            onChange={(e) => {
                                              let thisdoctor = doctor.list.find(
                                                (d) => d.id == e.target.value
                                              );
                                              setValues({
                                                ...values,
                                                date: "",
                                                doctorId: e.target.value,
                                                cost: values.isFollowUp
                                                  ? thisdoctor?.is_follow_up_cost ||
                                                    0
                                                  : thisdoctor?.appointment_cost ||
                                                    0,
                                              });
                                              handleChange(e);
                                              if (e.target.value) {
                                                getAvailableDates(
                                                  e.target.value,
                                                  values.type
                                                );
                                              } else {
                                                setAvailDates([]);
                                              }
                                            }}
                                            onBlur={handleBlur}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                          >
                                            <option value="">
                                              Select a doctor
                                            </option>
                                            {doctor.list.map((d, idx) => (
                                              <option
                                                key={idx}
                                                value={d.id}
                                              >{`${d?.first_name} ${d?.last_name}`}</option>
                                            ))}
                                          </select>
                                        )}
                                      </div>
                                    </div>
                                    {errors.doctorId && touched.doctorId ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.doctorId}
                                      </p>
                                    ) : null}
                                    <div className="relative flex items-start mt-4">
                                      <div className="flex h-5 items-center ml-4">
                                        <input
                                          checked={values.book_type}
                                          onChange={(e) => {
                                            setValues({
                                              ...values,
                                              book_type: e.target.checked,
                                            });
                                            handleChange(e);
                                            if (!e.target.checked) {
                                              if (values.doctorId) {
                                                getAvailableDates(
                                                  values.doctorId,
                                                  values.type
                                                );
                                                if (values.date) {
                                                  let date = moment(
                                                    values.date
                                                  ).format("YYYY-MM-DD");
                                                  getAvailableTimes(
                                                    parseInt(values.doctorId),
                                                    date,
                                                    values.type
                                                  );
                                                }
                                              }
                                            }
                                          }}
                                          onBlur={handleBlur}
                                          name="book_type"
                                          type="checkbox"
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                      </div>

                                      <div className="ml-3 text-sm">
                                        <label
                                          htmlFor="book_type"
                                          className="font-medium text-gray-700"
                                        >
                                          Is custom
                                        </label>
                                      </div>
                                    </div>
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="date"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Date
                                      </label>
                                      <div className="mt-1">
                                        <RcCalendar
                                          name="date"
                                          value={values.date}
                                          minDate={new Date()}
                                          minDetail="month"
                                          onChange={(value, e) => {
                                            if (values.book_type) {
                                              setValues({
                                                ...values,
                                                date: value,
                                              });
                                              handleChange(e);
                                            } else {
                                              let date =
                                                moment(value).format(
                                                  "YYYY-MM-DD"
                                                );
                                              setValues({
                                                ...values,
                                                time: "",
                                                date: value,
                                              });
                                              handleChange(e);
                                              if (value) {
                                                getAvailableTimes(
                                                  parseInt(values.doctorId),
                                                  date,
                                                  values.type
                                                );
                                              } else {
                                                setAvailTimes([]);
                                              }
                                            }
                                          }}
                                          tileDisabled={({
                                            activeStartDate,
                                            date,
                                            view,
                                          }) => {
                                            if (values.book_type) return false;
                                            return !availDates.includes(
                                              moment(date)
                                                .format("YYYY-MM-DD")
                                                .toString()
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {errors.date && touched.date ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.date}
                                      </p>
                                    ) : null}
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="time"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Time
                                      </label>
                                      <div className="mt-1">
                                        {values.book_type ? (
                                          <>
                                            <div className="flex space-x-2">
                                              <input
                                                type="time"
                                                name="time_start_time"
                                                value={
                                                  values.time.split("-")[0]
                                                }
                                                onChange={(e) => {
                                                  let existingTime =
                                                    values.time.split("-");
                                                  existingTime[0] =
                                                    e.target.value;
                                                  setValues({
                                                    ...values,
                                                    time_start_time:
                                                      e.target.value,
                                                    time: existingTime.join(
                                                      "-"
                                                    ),
                                                  });
                                                }}
                                                className="block w-full rounded-md border-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                              />
                                              <input
                                                type="time"
                                                name="time_end_time"
                                                value={
                                                  values.time.split("-")[1]
                                                }
                                                onChange={(e) => {
                                                  let existingTime =
                                                    values.time.split("-");
                                                  existingTime[1] =
                                                    e.target.value;
                                                  setValues({
                                                    ...values,
                                                    time_end_time:
                                                      e.target.value,
                                                    time: existingTime.join(
                                                      "-"
                                                    ),
                                                  });
                                                }}
                                                className="block w-full rounded-md border-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                              />
                                            </div>

                                            {errors.time_start_time ? (
                                              <p className="text-red-600 text-sm">
                                                {errors.time_start_time}
                                              </p>
                                            ) : null}
                                            {errors.time_end_time ? (
                                              <p className="text-red-600 text-sm">
                                                {errors.time_end_time}
                                              </p>
                                            ) : null}
                                          </>
                                        ) : (
                                          <select
                                            name="time"
                                            value={values.time}
                                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            onChange={(e) => {
                                              handleChange(e);
                                              setValues({
                                                ...values,
                                                time_end_time:
                                                  e.target.value.split("-")[1],
                                                time_start_time:
                                                  e.target.value.split("-")[0],
                                                time: e.target.value,
                                              });
                                            }}
                                            onBlur={handleBlur}
                                          >
                                            {!values.doctorId && (
                                              <option value="">
                                                Select a doctor first
                                              </option>
                                            )}
                                            {!values.date && (
                                              <option value="">
                                                Select a date first
                                              </option>
                                            )}
                                            {values.doctorId && values.date && (
                                              <option value="">
                                                Select a time
                                              </option>
                                            )}
                                            {availTimes.map((t, idx) => (
                                              <option
                                                key={idx}
                                                value={`${t.start_time}-${t.end_time}`}
                                              >{`${moment(
                                                t.start_time,
                                                "HH:mm"
                                              ).format("hh:mm A")} - ${moment(
                                                t.end_time,
                                                "HH:mm"
                                              ).format("hh:mm A")}`}</option>
                                            ))}
                                          </select>
                                        )}
                                      </div>
                                    </div>
                                    {errors.time && touched.time ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.time}
                                      </p>
                                    ) : null}
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="cost"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Discount
                                      </label>
                                      <div className="mt-1">
                                        <input
                                          type="number"
                                          min={"0"}
                                          onChange={handleChange}
                                          max={"100"}
                                          name="discount"
                                          value={values.discount}
                                          className="block w-full rounded-md border-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        ></input>
                                      </div>
                                    </div>
                                    {values.enrolled ? null : (
                                      <>
                                        <div className="sm:col-span-3 mt-4">
                                          <label
                                            htmlFor="cost"
                                            className="block text-sm font-medium text-gray-700"
                                          >
                                            Cost
                                          </label>
                                          <div className="mt-1">
                                            <input
                                              type="number"
                                              name="cost"
                                              value={values.cost}
                                              disabled={true}
                                              className="block w-full rounded-md border-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            ></input>
                                          </div>
                                        </div>
                                        {errors.cost && touched.cost ? (
                                          <p className="text-red-600 text-sm">
                                            {errors.cost}
                                          </p>
                                        ) : null}
                                        <div className="sm:col-span-3 mt-4">
                                          <label className="block text-sm font-medium text-gray-700">
                                            Type of payment
                                          </label>
                                          <div className="flex items-center">
                                            <input
                                              id="full-payment-type"
                                              name="payment_type"
                                              value="FULL"
                                              type="radio"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              checked={
                                                values.payment_type === "FULL"
                                              }
                                              className="my-3 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            />
                                            <label
                                              htmlFor="full-payment-type"
                                              className="ml-3 my-3 block text-sm font-medium text-gray-700"
                                            >
                                              Full
                                            </label>
                                          </div>
                                          <div className="flex items-center">
                                            <input
                                              id="advanced-payment-type"
                                              name="payment_type"
                                              value="ADVANCE"
                                              type="radio"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              checked={
                                                values.payment_type ===
                                                "ADVANCE"
                                              }
                                              // onClick={()=>setCostOpen(true)}
                                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                            />
                                            <label
                                              htmlFor="advanced-payment-type"
                                              className="ml-3 block text-sm font-medium text-gray-700"
                                            >
                                              Advance
                                            </label>
                                          </div>
                                        </div>

                                        {values.payment_type === "ADVANCE" && (
                                          <div className="sm:col-span-6 mt-4">
                                            <label
                                              htmlFor="advance_payment_amount"
                                              className="block text-sm font-medium text-gray-700"
                                            >
                                              Advanced payment cost
                                            </label>
                                            <div className="mt-1">
                                              <input
                                                type="number"
                                                name="advance_payment_amount"
                                                value={
                                                  values.advance_payment_amount
                                                }
                                                max={(values.cost || 1) - 1}
                                                onChange={handleChange}
                                                autoComplete="off"
                                                onBlur={handleBlur}
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                              />
                                            </div>
                                          </div>
                                        )}
                                        {values.payment_type === "ADVANCE" &&
                                        errors.advance_payment_amount &&
                                        touched.advance_payment_amount ? (
                                          <p className="text-red-600 text-sm">
                                            {errors.advance_payment_amount}
                                          </p>
                                        ) : null}
                                      </>
                                    )}

                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="remark"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Remark
                                      </label>
                                      <div className="mt-1">
                                        <textarea
                                          type="text"
                                          name="remark"
                                          value={values.remark}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="pt-5">
                                  <div className="flex justify-end items-center">
                                    <button
                                      type="button"
                                      onClick={closeModal}
                                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="submit"
                                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root
        show={modalData.type === "edit" && modalData.state}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => {
            if (!CommentModal.state) {
              editClose();
            }
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start ">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <div className="flex justify-between items-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Edit Appointment
                        </Dialog.Title>
                        <button
                          type="button"
                          onClick={() => deleteApp(modalData.data.id)}
                          disabled={appointment.delLoading}
                          className="rounded-md border border-red-600 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-300 hover:text-black focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          {appointment.delLoading
                            ? "Cancelling..."
                            : "Cancel appointment"}
                        </button>
                      </div>
                      <div className="mt-2">
                        <Formik
                          onSubmit={(values) => {
                            let start = values.time.split("-")[0];
                            let end = values.time.split("-")[1];
                            dispatch(
                              editAppointment(modalData.data.id, {
                                type: values.type,
                                doctorId: parseInt(values.doctorId),
                                locationId: parseInt(values.locationId),
                                date: moment(values.date).format("YYYY-MM-DD"),
                                start_time: start,
                                end_time: end,
                                payment_type:
                                  values.payment_type !== "FULL"
                                    ? values.advance_payment_amount ===
                                      values.cost
                                      ? "FULL"
                                      : "ADVANCE"
                                    : "FULL",
                                cost: values.cost,
                                remark: values.remark,
                                isFollowUp: values.isFollowUp,
                                status: "TENTATIVE",
                                advance_payment_amount:
                                  values.advance_payment_amount
                                    ? values.advance_payment_amount
                                    : null,
                                book_type: values.book_type
                                  ? "CUSTOM"
                                  : "NORMAL",
                              })
                            );
                            editClose();
                          }}
                          initialValues={{
                            ...modalData.data,
                            date: new Date(
                              moment(modalData.data.date).format("YYYY-MM-DD")
                            ),
                            time: `${moment(
                              modalData.data.start_time?.split("T")[1],
                              "HH:mm:ss"
                            ).format("HH:mm")}-${moment(
                              modalData.data.end_time?.split("T")[1],
                              "HH:mm:ss"
                            ).format("HH:mm")}`,
                            book_type:
                              modalData.data.book_type === "CUSTOM"
                                ? true
                                : false,
                          }}
                          validationSchema={UpdateAppointmentSchema}
                        >
                          {({
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setValues,
                            initialValues,
                            values,
                            touched,
                            errors,
                          }) => (
                            <form
                              noValidate
                              onSubmit={handleSubmit}
                              className="space-y-8 divide-y divide-gray-200"
                            >
                              <div className="space-y-8 divide-y divide-gray-200">
                                <div>
                                  <div>
                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="type"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Type
                                      </label>
                                      <div className="mt-1">
                                        <select
                                          value={values.type}
                                          onChange={(e) => {
                                            handleChange(e);
                                            getAvailableTimes(
                                              parseInt(values.doctorId),
                                              moment(values.date).format(
                                                "YYYY-MM-DD"
                                              ),
                                              e.target.value
                                            );
                                            setValues({
                                              ...values,
                                              type: e.target.value,
                                            });
                                          }}
                                          onBlur={handleBlur}
                                          name="type"
                                          id="type"
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        >
                                          <option value={null}>
                                            Select a type
                                          </option>
                                          <option value="OFFLINE">
                                            Offline
                                          </option>
                                          <option value="ONLINE">Online</option>
                                        </select>
                                      </div>
                                    </div>

                                    <div className="relative flex items-start mt-4">
                                      <div className="flex h-5 items-center">
                                        <input
                                          checked={values.isFollowUp}
                                          onChange={(e) => {
                                            let thisdoctor = doctor.list.find(
                                              (d) => d.id == values.doctorId
                                            );
                                            setValues({
                                              ...values,
                                              isFollowUp: e.target.checked,
                                              cost: e.target.checked
                                                ? thisdoctor?.is_follow_up_cost ||
                                                  0
                                                : thisdoctor?.appointment_cost ||
                                                  0,
                                            });
                                            handleChange(e);
                                          }}
                                          onBlur={handleBlur}
                                          name="isFollowUp"
                                          type="checkbox"
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                      </div>
                                      <div className="ml-3 text-sm">
                                        <label
                                          htmlFor="isFollowUp"
                                          className="font-medium text-gray-700"
                                        >
                                          Is Follow Up
                                        </label>
                                      </div>
                                    </div>
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="doctorId"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Doctor
                                      </label>
                                      <div className="mt-1">
                                        {doctor.loading ? (
                                          <span className="text-sm text-indigo-700">
                                            Fetching doctors
                                          </span>
                                        ) : doctor.error !== null ? (
                                          <span className="text-sm text-red-700">
                                            {doctor.error}
                                          </span>
                                        ) : (
                                          <select
                                            id="doctorId"
                                            name="doctorId"
                                            value={values.doctorId}
                                            onChange={(e) => {
                                              let thisdoctor = doctor.list.find(
                                                (d) => d.id == e.target.value
                                              );
                                              setValues({
                                                ...values,
                                                date: "",
                                                doctorId: e.target.value,
                                                cost: values.isFollowUp
                                                  ? thisdoctor?.is_follow_up_cost ||
                                                    0
                                                  : thisdoctor?.appointment_cost ||
                                                    0,
                                              });
                                              handleChange(e);
                                              if (e.target.value) {
                                                getAvailableDates(
                                                  e.target.value,
                                                  values.type
                                                );
                                              } else {
                                                setAvailDates([]);
                                              }
                                            }}
                                            onBlur={handleBlur}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                          >
                                            <option value="">
                                              Select a doctor
                                            </option>
                                            {doctor.list.map((d, idx) => (
                                              <option
                                                key={idx}
                                                value={d.id}
                                              >{`${d?.first_name} ${d?.last_name}`}</option>
                                            ))}
                                          </select>
                                        )}
                                      </div>
                                    </div>
                                    {errors.doctorId && touched.doctorId ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.doctorId}
                                      </p>
                                    ) : null}
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="doctorId"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Locations
                                      </label>
                                      <div className="mt-1">
                                        <select
                                          id="locationId"
                                          name="locationId"
                                          value={values.locationId}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        >
                                          <option>Select a location</option>
                                          {location.list.map((l, index) => (
                                            <option key={index} value={l.id}>
                                              {l.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="relative flex items-start mt-4">
                                      <div className="flex h-5 items-center ml-4">
                                        <input
                                          checked={values.book_type}
                                          onChange={(e) => {
                                            setValues({
                                              ...values,
                                              book_type: e.target.checked,
                                            });
                                            handleChange(e);
                                            if (!e.target.checked) {
                                              if (values.doctorId) {
                                                getAvailableDates(
                                                  values.doctorId
                                                );
                                                if (values.date) {
                                                  let date = moment(
                                                    values.date
                                                  ).format("YYYY-MM-DD");
                                                  getAvailableTimes(
                                                    parseInt(values.doctorId),
                                                    date,
                                                    values.type
                                                  );
                                                }
                                              }
                                            }
                                          }}
                                          onBlur={handleBlur}
                                          name="book_type"
                                          type="checkbox"
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                      </div>

                                      <div className="ml-3 text-sm">
                                        <label
                                          htmlFor="book_type"
                                          className="font-medium text-gray-700"
                                        >
                                          Is custom
                                        </label>
                                      </div>
                                    </div>
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="date"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Date
                                      </label>
                                      <div className="mt-1">
                                        <RcCalendar
                                          name="date"
                                          value={values.date}
                                          minDate={new Date()}
                                          minDetail="month"
                                          onChange={(value, e) => {
                                            if (values.book_type) {
                                              setValues({
                                                ...values,
                                                date: value,
                                              });
                                              handleChange(e);
                                            } else {
                                              let date =
                                                moment(value).format(
                                                  "YYYY-MM-DD"
                                                );
                                              setValues({
                                                ...values,
                                                time: "",
                                                date: value,
                                              });
                                              handleChange(e);
                                              if (value) {
                                                getAvailableTimes(
                                                  parseInt(values.doctorId),
                                                  date,
                                                  values.type
                                                );
                                              } else {
                                                setAvailTimes([]);
                                              }
                                            }
                                          }}
                                          tileDisabled={({
                                            activeStartDate,
                                            date,
                                            view,
                                          }) => {
                                            if (values.book_type) return false;
                                            return !availDates.includes(
                                              moment(date)
                                                .format("YYYY-MM-DD")
                                                .toString()
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {errors.date && touched.date ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.date}
                                      </p>
                                    ) : null}
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="time"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Time&nbsp;
                                        {initialValues.doctorId ===
                                          values.doctorId &&
                                        initialValues.date === values.date
                                          ? initialValues.time
                                          : ""}
                                      </label>
                                      <div className="mt-1">
                                        {values.book_type ? (
                                          <div className="flex space-x-2">
                                            <input
                                              type="time"
                                              name="time_start_time"
                                              value={values.time.split("-")[0]}
                                              onChange={(e) => {
                                                let existingTime =
                                                  values.time.split("-");
                                                existingTime[0] =
                                                  e.target.value;
                                                setValues({
                                                  ...values,
                                                  time: existingTime.join("-"),
                                                });
                                              }}
                                              className="block w-full rounded-md border-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                            <input
                                              type="time"
                                              name="time_end_time"
                                              value={values.time.split("-")[1]}
                                              onChange={(e) => {
                                                let existingTime =
                                                  values.time.split("-");
                                                existingTime[1] =
                                                  e.target.value;
                                                setValues({
                                                  ...values,
                                                  time: existingTime.join("-"),
                                                });
                                              }}
                                              className="block w-full rounded-md border-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                          </div>
                                        ) : (
                                          <select
                                            name="time"
                                            value={values.time}
                                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          >
                                            {!values.doctorId && (
                                              <option value="">
                                                Select a doctor first
                                              </option>
                                            )}
                                            {!values.date && (
                                              <option value="">
                                                Select a date first
                                              </option>
                                            )}
                                            {values.doctorId && values.date && (
                                              <option value="">
                                                Select a time
                                              </option>
                                            )}
                                            {availTimes.map((t, idx) => (
                                              <option
                                                key={idx}
                                                value={`${t.start_time}-${t.end_time}`}
                                              >{`${moment(
                                                t.start_time,
                                                "HH:mm"
                                              ).format("hh:mm A")} - ${moment(
                                                t.end_time,
                                                "HH:mm"
                                              ).format("hh:mm A")}`}</option>
                                            ))}
                                          </select>
                                        )}
                                      </div>
                                    </div>
                                    {errors.time && touched.time ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.date}
                                      </p>
                                    ) : null}

                                    <div>
                                      <div className="sm:col-span-3 mt-4">
                                        <label
                                          htmlFor="cost"
                                          className="block text-sm font-medium text-gray-700"
                                        >
                                          Cost
                                        </label>
                                        <div className="mt-1">
                                          <input
                                            type="number"
                                            name="cost"
                                            value={values.cost}
                                            disabled={true}
                                            className="block w-full rounded-md disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                          ></input>
                                        </div>
                                      </div>
                                      {errors.cost && touched.cost ? (
                                        <p className="text-red-600 text-sm">
                                          {errors.cost}
                                        </p>
                                      ) : null}
                                      <div className="sm:col-span-3 mt-4">
                                        <label className="block text-sm font-medium text-gray-700">
                                          Type of payment
                                        </label>
                                        <div className="flex items-center">
                                          <input
                                            id="full-payment-type"
                                            name="payment_type"
                                            value="FULL"
                                            type="radio"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={
                                              values.payment_type === "FULL"
                                            }
                                            className="my-3 h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                          />
                                          <label
                                            htmlFor="full-payment-type"
                                            className="ml-3 my-3 block text-sm font-medium text-gray-700"
                                          >
                                            Full
                                          </label>
                                        </div>
                                        <div className="flex items-center">
                                          <input
                                            id="advanced-payment-type"
                                            name="payment_type"
                                            value="ADVANCE"
                                            type="radio"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            checked={
                                              values.payment_type === "ADVANCE"
                                            }
                                            // onClick={()=>setCostOpen(true)}
                                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                          />
                                          <label
                                            htmlFor="advanced-payment-type"
                                            className="ml-3 block text-sm font-medium text-gray-700"
                                          >
                                            Advance
                                          </label>
                                        </div>
                                      </div>
                                      {values.payment_type === "ADVANCE" && (
                                        <div className="sm:col-span-6 mt-4">
                                          <label
                                            htmlFor="advance_payment_amount"
                                            className="block text-sm font-medium text-gray-700"
                                          >
                                            Advanced payment cost
                                          </label>
                                          <div className="mt-1">
                                            <input
                                              type="number"
                                              name="advance_payment_amount"
                                              value={
                                                values.advance_payment_amount
                                              }
                                              max={(values.cost || 1) - 1}
                                              onChange={handleChange}
                                              autoComplete="off"
                                              onBlur={handleBlur}
                                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                          </div>
                                        </div>
                                      )}
                                      {values.payment_type === "ADVANCE" &&
                                      errors.advance_payment_amount &&
                                      touched.advance_payment_amount ? (
                                        <p className="text-red-600 text-sm">
                                          {errors.advance_payment_amount}
                                        </p>
                                      ) : null}
                                    </div>

                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="remark"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Remark
                                      </label>
                                      <div className="mt-1">
                                        <textarea
                                          type="text"
                                          name="remark"
                                          value={values.remark}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="pt-5">
                                  <div className="flex justify-end">
                                    {/* {!isValid && (
                                    <span className="font-light text-red-500 mr-2">
                                      Please complete the form.
                                    </span>
                                  )} */}
                                    <button
                                      type="button"
                                      onClick={editClose}
                                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="submit"
                                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                      {appointment.uLoading
                                        ? "Updating..."
                                        : "Update"}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={CommentModal.state} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelCancleButtonRef}
          onClose={() =>
            setCommentModal((prev) => ({ ...prev, state: false, id: null }))
          }
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <h1 className="text-xl text-center">
                      Reason for Cancel Appointment
                    </h1>
                  </div>
                  <div className="mt-2 w-full flex items-center justify-center">
                    <Formik
                      initialValues={{
                        cancel_reason: "",
                      }}
                      // validationSchema={Yup.object({
                      //   cancel_comment: Yup.string().required("Required"),
                      // })}

                      onSubmit={(values) => {
                        console.log(values);
                      }}
                    >
                      {({ handleSubmit, values, handleBlur, handleChange }) => (
                        <form
                          className="w-[80%]"
                          onSubmit={(e) => {
                            e.preventDefault();
                            console.log(values);
                          }}
                        >
                          {console.log(values)}
                          <div className="mt-2 flex items-center justify-between">
                            <select
                              id="cancel_reason"
                              name="cancel_reason"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.cancel_reason}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            >
                              <option value="">Select a Reason</option>
                              <option value="SENDER_SIDE">Sender Side</option>
                              <option value="PATIENT_SIDE">Patient Side</option>
                            </select>
                          </div>

                          <div>
                            <div className="mt-5 sm:mt-6 flex items-center justify-between gap-5">
                              <button
                                type="button"
                                onClick={async () => {
                                  try {
                                    await app_api.post(
                                      `/api/appointment/cancel/${CommentModal.id}`,
                                      {
                                        cancel_reason: values.cancel_reason,
                                      }
                                    );

                                    toast.success(
                                      "Appointment cancelled successfully"
                                    );

                                    setCommentModal((prev) => ({
                                      ...prev,
                                      state: false,
                                      id: null,
                                    }));
                                  } catch (error) {
                                    toast.error("Something went wrong");
                                  }
                                }}
                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              >
                                Submit
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setCommentModal((prev) => ({
                                    ...prev,
                                    state: false,
                                    id: null,
                                  }));
                                  editClose();
                                }}
                                ref={cancelCancleButtonRef}
                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export function AddPatientModal({ addPatientModal, setaddPatientModal }) {
  const [rfshowModal, setrfShowModal] = useState(false);
  const dispatch = useDispatch();
  const initialValues = {
    firstName: "",
    lastName: "",
    fatherFirstName: "",
    fatherLastName: "",
    motherFirstName: "",
    motherLastName: "",
    guardiansFirstName: "",
    guardiansLastName: "",
    location_id: "",
    phoneNo: "",
    dob: "",
    address: "",
  };
  const { list: referralList } = useSelector((state) => state.referral);
  const { list: locationList } = useSelector((state) => state.location);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllReferral());
    dispatch(getAllLocations());
  }, []);
  const addPatient = async (body) => {
    try {
      const headers = dispatch(getHeaders());
      const { data } = await axios.post(`${API}/api/patient`, body, {
        headers,
      });
      console.log(data.data);
      toast.success("Patient added successfully!");
      setaddPatientModal(false);
    } catch (error) { }
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setValues,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: patientsSchema,
    onSubmit: (values, action) => {
      const body = {
        ...values,
        referral_id: parseInt(values.referral_id),
        location_id: parseInt(values.location_id),
      };

      body.dob = moment(values.dob).format();
      if (!body.referral_id) delete body.referral_id;
      let flags = 0;
      flags += body.fatherFirstName && body.fatherLastName ? 1 : 0;
      flags += body.motherFirstName && body.motherLastName ? 1 : 0;
      flags += body.guardiansFirstName && body.guardiansLastName ? 1 : 0;
      if (flags < 1) {
        toast.error("atleast two guardians info are required");
      } else {
        addPatient(body);
        action.resetForm();
      }
    },
  });
  // console.log(errors)

  return (
    <Transition.Root show={addPatientModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setaddPatientModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start ">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center font-medium leading-6 text-gray-900"
                    >
                      Add Patient
                    </Dialog.Title>
                    <div className="mt-2">
                      <form
                        noValidate
                        onSubmit={handleSubmit}
                        className="space-y-8 divide-y divide-gray-200"
                      >
                        <div className="space-y-6 sm:space-y-5">
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="firstName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              First Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                id="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.firstName && touched.firstName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.firstName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="lastName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Last Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                id="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.lastName && touched.lastName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.lastName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="fatherFirstName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Father First Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="fatherFirstName"
                                placeholder="Father First Name"
                                id="fatherFirstName"
                                value={values.fatherFirstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.fatherFirstName &&
                                touched.fatherFirstName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.fatherFirstName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="fatherLastName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Father Last Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="fatherLastName"
                                placeholder="Father Last Name"
                                id="fatherLastName"
                                value={values.fatherLastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.fatherLastName &&
                                touched.fatherLastName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.fatherLastName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="motherFirstName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Mother First Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="motherFirstName"
                                placeholder="mother First Name"
                                id="motherFirstName"
                                value={values.motherFirstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.motherFirstName &&
                                touched.motherFirstName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.motherFirstName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="motherLastName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Mother Last Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="motherLastName"
                                placeholder="Mother Last Name"
                                id="motherLastName"
                                value={values.motherLastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.motherLastName &&
                                touched.motherLastName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.motherLastName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="guardiansFirstName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Guardian First Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="guardiansFirstName"
                                placeholder="Guardian First Name"
                                id="guardiansFirstName"
                                value={values.guardiansFirstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.guardiansFirstName &&
                                touched.guardiansFirstName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.guardiansFirstName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="guardiansLastName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Guardian Last Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="guardiansLastName"
                                id="guardiansLastName"
                                placeholder="Guardian Last Name"
                                value={values.guardiansLastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.guardiansLastName &&
                                touched.guardiansLastName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.guardiansLastName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="phone"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Phone
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="phoneNo"
                                placeholder="Phone Number"
                                id="phone"
                                value={values.phoneNo}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.phoneNo && touched.phoneNo ? (
                                <p className="text-red-600 text-sm">
                                  {errors.phoneNo}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="dob"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Date of birth
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="date"
                                name="dob"
                                id="dob"
                                value={values.dob}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.dob && touched.dob ? (
                                <p className="text-red-600 text-sm">
                                  {errors.dob}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="dob"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Gender
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <select
                                id="gender"
                                name="gender"
                                value={values.gender}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                              >
                                <option selected>Select Gender</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="O">Others</option>
                              </select>
                              {errors.gender && touched.gender ? (
                                <p className="text-red-600 text-sm">
                                  {errors.gender}
                                </p>
                              ) : null}
                            </div>
                          </div>

                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="dob"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Locations
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <select
                                id="location_id"
                                name="location_id"
                                value={values.location_id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                              >
                                <option value="all">Select a Location</option>

                                {locationList.map((d, idx) => (
                                  <option key={idx} value={d.id}>
                                    {d.name}
                                  </option>
                                ))}
                              </select>
                              {errors.location_id && touched.location_id ? (
                                <p className="text-red-600 text-sm">
                                  {errors.location_id}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="referral_id"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Select a Referral
                            </label>
                            <div className="mt-1 grid grid-cols-2 sm:col-span-2 sm:mt-0">
                              <div className="mt-1 flex  sm:col-span-2 sm:mt-0">
                                <Select
                                  options={extractIdAndName(referralList)}
                                  name="patientId"
                                  onChange={(value, e) => {
                                    setValues({
                                      ...values,
                                      referral_id: value.value,
                                    });
                                  }}
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                                />

                                <button
                                  type="button"
                                  onClick={() => {
                                    setrfShowModal(true);
                                  }}
                                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 items-center "
                                >
                                  <AiOutlinePlus size={23} />
                                  <AddRefferalModal
                                    showModal={rfshowModal}
                                    setShowModal={setrfShowModal}
                                  />
                                </button>
                              </div>
                              {errors.referral_id && touched.referral_id ? (
                                <p className="text-red-600 text-sm">
                                  {errors.referral_id}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="address"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Address
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <textarea
                              className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                              type="text"
                              name="address"
                              id="address"
                              placeholder="Address"
                              value={values.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.address && touched.address ? (
                              <p className="text-red-600 text-sm">
                                {errors.address}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              type="submit"
                              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
