import axios from "axios";
import { getHeaders } from "../auth/actions";
import { toast } from "react-hot-toast";
import {
  GET_ALL_OPD_REPORT_BEGIN,
  GET_ALL_OPD_REPORT_SUCCESS,
  GET_ALL_OPD_REPORT_ERROR,
  GET_ALL_CANCELLED_OPD,
  GET_ALL_CANCELLED_OPD_BEGIN,
  GET_ALL_CANCELLED_OPD_ERROR,
} from "./constants";

const API = process.env.REACT_APP_API_URL;

export const getCancelledOpdAppointment = (limit) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_CANCELLED_OPD_BEGIN,
    });
    const headers = dispatch(getHeaders());
    const { data } = await axios.get(
      `${API}/api/appointment/canceled-appointments?limit=${limit}`,
      { headers }
    );
    dispatch({
      type: GET_ALL_CANCELLED_OPD,
      data: data.data,
    });
  } catch (err) {
    const msg = err.response.data.message;
    toast.error(msg);
    dispatch({
      type: GET_ALL_CANCELLED_OPD_ERROR,
      error: msg,
    });
  }
};

export const getOpdAppointmentWithId =
  (id, limit = 200, date = "", endDate = "") =>
  async (dispatch) => {
    try {
      dispatch({
        type: GET_ALL_OPD_REPORT_BEGIN,
      });
      const headers = dispatch(getHeaders());
      const { data } = await axios.get(
        `${API}/api/appointment/opd-appointments/${id}?limit=${limit}&date=${date}&endDate=${endDate}`,
        { headers }
      );
      console.log(data.data);
      if (data.data.length === 0) {
        toast.error("No data found on this date");
      }
      dispatch({
        type: GET_ALL_OPD_REPORT_SUCCESS,
        data: data.data,
      });
    } catch (error) {
      const msg = error.response.data.message;
      toast.error(msg);
      dispatch({
        type: GET_ALL_OPD_REPORT_ERROR,
        error: msg,
      });
    }
  };
