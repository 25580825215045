import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { Formik } from "formik";
import React, { Component, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { AddPatientModal } from "../appointment";
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { useAsyncDebounce, useTable } from "react-table";
import { getAllPatients } from "../../../redux/patient/actions";
import axios from "axios";
import Select from "react-select";
import moment from "moment";
import { getHeaders } from "../../../redux/auth/actions";
import { toast } from "react-hot-toast";
import ByEv from "./ByEv";
import { getAllLocations } from "../../../redux/location/actions";
import { getAllDoctors } from "../../../redux/doctor/actions";
import { classNames } from "../../../utilities/classname";
import { useRef } from "react";
import { Dna } from "react-loader-spinner";
import { FiLoader } from "react-icons/fi";

import {
  changeActiveDepartmentTab,
  clearDepartment,
  clearPatientRecommendation,
  getDepartmentsByProgramId,
  getPatientRecommandationWithId,
} from "../../../redux/patientrecommandation/actions";
import { getAllTherapistInDepartmentByDays } from "../../../redux/therapist/therpist/actions";
import { GrCheckmark } from "react-icons/gr";
import { RxCross2 } from "react-icons/rx";
import Modal from "../../../components/Modal";
import Table from "../../../components/tables/table";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { get } from "react-hook-form";
import { old_booking_Columns } from "../../../components/tables/tableheader";
import app_api from "../../../utilities/api";

const initProgramTabs = [
  {
    name: "Program",
    indicator: "program",
    current: true,
  },
  { name: "Evaluation", indicator: "ev", current: false },
];

const DynamicTable = ({ data, columns }) => {
  console.log(data);
  return (
    <div className="mt-4 flex h-max flex-col">
      <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Sl no
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Time
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {data.map((item, idx) => (
                  <tr key={idx}>
                    <td className="px-6 py-4 whitespace-nowrap">{idx + 1}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.date}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item.time_slot}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

function toWord(num) {
  switch (num) {
    case 1:
      return "ONCE";
    case 2:
      return "TWICE";
    case 3:
      return "THRICE";
    default:
      return num;
  }
}

function AddTherapistAppointment() {
  const myRef = useRef(null);

  const dispatch = useDispatch();
  const { id: AppointmentId } = useParams();
  const navigate = useNavigate();
  const {
    appointment,
    exception,
    location,
    patient,
    doctor,
    recommendation,
    therapist,
  } = useSelector((state) => ({
    appointment: state.appointment,
    exception: state.exception,
    location: state.location,
    patient: state.patient,
    doctor: state.doctor,
    recommendation: state.patientRecommadation,
    therapist: state.Therapist,
  }));
  const API = process.env.REACT_APP_API_URL;

  const [programTabs, setProgramTabs] = useState(initProgramTabs);
  const [rpId, setRpId] = useState(null);
  const [availDates, setAvailDates] = useState([]);
  const [availTimeSlots, setAvailTimeSlots] = useState([]);
  const [availableTable, setAvailableTable] = useState({
    headers: [],
    data: [],
    show: false,
  });
  useEffect(() => {
    dispatch(getAllPatients());
    dispatch(getAllLocations());
    dispatch(getAllDoctors());
  }, []);

  const [allPatients, setAllPatients] = useState({
    data: [],
    loading: true,
    error: null,
    query: "",
  });

  useEffect(() => {
    setAllPatients((prev) => ({ ...prev, loading: true }));
    app_api
      .get(
        `/api/patient/search?limit=20${
          allPatients.query ? `&query=${allPatients.query}` : ""
        }`
      )
      .then((res) => {
        setAllPatients((prev) => ({
          ...prev,
          data: res.data.data,
          loading: false,
        }));
      });
  }, [allPatients.query]);

  const onFilterChange = useAsyncDebounce((value) => {
    setAllPatients((prev) => ({ ...prev, query: value }));
  }, 100);

  const pages = [
    { title: "Appointments", href: "/therapist-master", current: false },
  ];

  const goBack = () => {
    navigate(-1);
  };

  const onChangeProgramTab = (name) => {
    let tabs = Array.from(programTabs);
    if (tabs.filter((t) => t.name === name).length === 1) {
      tabs = tabs.map((t) => {
        if (t.name === name) return { ...t, current: true };
        else return { ...t, current: false };
      });
    } else {
      tabs = tabs.map((t, idx) => {
        if (idx === 0) return { ...t, current: true };
        else return { ...t, current: false };
      });
    }
    setProgramTabs(tabs);
  };

  const getAvailableDates = (id) => {
    let headers = dispatch(getHeaders());
    axios
      .get(`${API}/api/therapist/appointment/get-appointment-dates/${id}`, {
        headers,
      })
      .then((res) => {
        setAvailDates(res.data.data);
      });
  };
  function flattenArrayOfArraysOrKeepArray(arrayOrArrayOfArrays) {
    if (Array.isArray(arrayOrArrayOfArrays[0])) {
      return arrayOrArrayOfArrays.reduce((result, currentArray) => {
        return result.concat(currentArray);
      }, []);
    } else {
      return arrayOrArrayOfArrays;
    }
  }
  const getTimeSlots = (
    therapist_id,
    dates,
    appointment_type = "ONLINE",
    locationId,
    departId,
    days
  ) => {
    let headers = dispatch(getHeaders());
    if (!!therapist_id) {
      axios
        .post(
          `${API}/api/therapist/appointment/get-appointment-time-slots`,
          {
            therapist_id,
            date: dates,
            appointment_type,
            query_type: therapist_id ? "THERAPIST" : "ALL",
          },
          {
            headers,
          }
        )
        .then((res) => {
          setAvailTimeSlots(
            flattenArrayOfArraysOrKeepArray(res.data.data).map((t) => ({
              value: `${t.start_time}-${t.end_time} ${
                t.therapist_id ? "-" + t.therapist_id : ""
              }`,
              label: `${t.start_time}-${t.end_time} ${
                t.therapist_name ? " - " + t.therapist_name : ""
              }`,
              therapist_id: t.therapist_id,
            }))
          );
        });
    } else {
      axios
        .post(
          `${API}/api/therapist/appointment/get-time-slots/all-therapist`,
          {
            location_id: Number(locationId),
            department_id: Number(departId),
            date: dates,
            appointment_type,
            days: days.map((d) => d.value),
          },
          {
            headers,
          }
        )
        .then((res) => {
          setAvailTimeSlots(
            res.data.data.map((t) => ({
              value: `${t.start_time}-${t.end_time}-${t.therapist_id}`,
              label: `${t.start_time}-${t.end_time} ${
                t.therapist_name ? " - " + t.therapist_name : ""
              }`,
              therapist_id: t.therapist_id,
            }))
          );
        });
    }
  };

  const bookAppointment = (values) => {
    const body = {
      typeOfAppointment: values.typeOfAppointment,
      patient_id: Number(values.patientID),
      appointment_type: "NORMAL",
      patient_main_recommendation_id: Number(values.rp_id),
      recommended_program_id: Number(values.rp_id),
      department_id: Number(values.department),
      location_id: Number(values.location_id),
      therapist_id: Number(values.therapist_id),
      date: values.lockTimeSlots,
      discount_percent: Number(values.discount_percent),
    };
    let headers = dispatch(getHeaders());
    axios
      .post(`${API}/api/therapist/appointment/create`, values, {
        headers,
      })
      .then((res) => {
        toast.success(`${values._department} Sessions Booked Successfully`);
        // navigate(-1);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  function updateLock(oldArray, newArray) {
    for (let newItem of newArray) {
      let dateFound = false;
      for (let i = 0; i < oldArray.length; i++) {
        if (oldArray[i].date === newItem.date) {
          dateFound = true;
          oldArray[i].time_slot = newItem.time_slot;
          break;
        }
      }
      if (!dateFound) {
        oldArray.push(newItem);
      }
    }
    return oldArray;
  }

  const AvailabilityTable = ({
    Theaders,
    enable,
    setValues,
    allDates,
    values,
    therapist_id,
    appointment_type,
  }) => {
    console.log(enable);
    useEffect(() => {
      if (!enable) {
        if (!enable && therapist_id && appointment_type && allDates.length > 0)
          getAvaill();
      }
    }, [therapist_id, appointment_type, allDates]);
    const [data, setData] = useState([]);
    const getAvaill = async () => {
      let headers = dispatch(getHeaders());
      const newData = [];

      for (const header of Theaders) {
        try {
          const res = await axios.post(
            `${API}/api/therapist/appointment/get-appointment-time-slots`,
            {
              therapist_id,
              date: allDates.filter(
                (date) => moment(date, "YYYY-MM-DD").format("dddd") === header
              ),
              appointment_type,
              query_type: therapist_id ? "THERAPIST" : "ALL",
            },
            {
              headers,
            }
          );

          newData.push(res.data.data.length > 0);
        } catch (err) {
          newData.push(false);
        }
      }
      if (newData.length > 0) {
        setData(newData);
      } else {
        setData([...data]);
      }
      setValues({
        ...values,
        availtable: true,
      });
    };

    console.log(data);
    return (
      <div className="mt-4 mb-4 flex flex-col w-[50%]">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {Theaders.map((header, index) => (
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {data.map((item, index) => (
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item ? (
                          <GrCheckmark className="text-green-700 " />
                        ) : (
                          <RxCross2 className="text-red-700" />
                        )}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const initState = {
    state: false,
  };
  const [Bookings, setBookings] = useState({
    data: [],
    loading: true,
  });
  async function getBookings(body) {
    let headers = dispatch(getHeaders());
    try {
      const res = await axios.post(
        `${API}/api/therapist/appointment/appointment-session`,
        body,
        {
          headers,
        }
      );
      setBookings({
        data: res.data.data ? res.data.data : [],
        loading: false,
      });
    } catch (err) {
      toast.error(err.message);
    }
  }
  const [renderOldBookingModal, setRenderOldBookingModal] = useState(initState);
  const OldBookingModal = () => {
    const { state } = renderOldBookingModal;
    return (
      <Modal
        open={state}
        setOpen={() =>
          setRenderOldBookingModal((prev) => {
            return {
              ...prev,
              state: false,
            };
          })
        }
        title="Old Booking"
        size="max-w-5xl"
      >
        {Bookings.loading ? (
          <FallingLinesLoader />
        ) : (
          <Table data={Bookings.data} columns={old_booking_Columns()} />
        )}
      </Modal>
    );
  };
  const [hoidayList, setHolidayList] = useState({
    loading: false,
    data: [],
  });
  const api = process.env.REACT_APP_API_URL;
  const getAllHolidays = async () => {
    const headers = await dispatch(getHeaders());
    setHolidayList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(`${api}/api/holiday-master`, {
        headers: headers,
      });
      const { data, status } = res;
      if (status === 200) {
        setHolidayList((prev) => ({
          ...prev,
          data: data.data,
          loading: false,
        }));
      }
      console.log(res);
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    getAllHolidays();
  }, []);
  return (
    <div className="px-4 h-max sm:px-6 lg:px-8">
      {OldBookingModal()}
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={goBack}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>
          Back
        </button>
        <div className="sm:flex-auto border-b pb-5">
          <h1 className="text-lg font-medium leading-6 text-gray-900">
            Add Appointment
          </h1>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            This information will be displayed publicly so be careful what you
            share.
          </p>
        </div>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {programTabs.map((tab) => (
              <span
                key={tab.name}
                className={classNames(
                  tab.current
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 cursor-pointer px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.current ? "page" : undefined}
                onClick={() => onChangeProgramTab(tab.name)}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </div>
      <div className="mt-5">
        {programTabs.find((t) => t.current).indicator === "program" && (
          <Formik
            initialValues={{
              typeOfAppointment: "",
              patientID: "",
              patient: "",
              typeOfTherapistAppointment: "PROGRAM",
              rp_id: "",
              therapist_id: "",
              location_id: "",
              date: "",
              timeSlots: "",
              remarks: "",
              department: "",
              _department: "",
              remark: "",
              days: [],
              dates: [],
              weekDays: [],
              weekDay: "",
              timeSlot: "",
              requireSlots: "",
              lockDates: [],
              discount_type: "NO_DISCOUNT",
              lockTimeSlots: [],
              availtable: false,
              sProgramId: "",
              locationError: "",
              discount_percent: "",
            }}
            enableReinitialize={true}
            validationSchema={null}
            onSubmit={async (values, action) => {
              const body = {
                typeOfAppointment: values.typeOfAppointment,
                patient_id: Number(values.patientID),
                appointment_type: "NORMAL",
                patient_main_recommendation_id: Number(values.rp_id),
                recommended_program_id: Number(values.rp_id),
                department_id: Number(values.department),
                location_id: Number(values.location_id),
                therapist_id: values.therapist_id
                  ? Number(values.therapist_id)
                  : Number(
                      availTimeSlots.find((t) => t.value === values.timeSlot)
                        .therapist_id
                    ),
                date: values.lockTimeSlots.map((d, idx) => ({
                  ...d,
                  time_slot: `${d.time_slot.split("-")[0]}-${
                    d.time_slot.split("-")[1]
                  }`,
                  therapist_id: Number(d.time_slot.split("-")[2])
                    ? Number(d.time_slot.split("-")[2])
                    : values.therapist_id
                    ? Number(values.therapist_id)
                    : Number(
                        availTimeSlots.find((t) => t.value === values.timeSlot)
                          .therapist_id
                      ),
                })),
                _department: values._department,
                discount_percent: Number(values.discount_percent),
              };
              const isHolidayd = values.lockTimeSlots.filter((x) => {
                const isHoliday = hoidayList.data.find(
                  (y) =>
                    moment(y.date).format("DD/MM/YYYY") ===
                    moment(x.date).format("DD/MM/YYYY")
                );
                console.log("x.date:", x.date);
                console.log("isHoliday:", isHoliday);
                if (isHoliday) {
                  return true;
                }
                return false;
              });
              if (isHolidayd.length > 0) {
                toast.error("Selected Dates include Holidays");
              } else {
                if (values.requireSlots === values.lockTimeSlots.length) {
                  bookAppointment(body);
                  // window.location.reload();
                  let headers = dispatch(getHeaders());
                  const res = await axios.get(
                    `${API}/api/therapist/appointment/verify-appointment/${values.rp_id}/${values.patientID}`,
                    {
                      headers,
                    }
                  );
                  if (res.data.data === true) {
                    dispatch(clearPatientRecommendation());
                    action.resetForm();
                    window.location.reload();
                  }
                } else {
                  toast.error(
                    `time slots are locked please select for ${values.weekDays
                      .filter((d) => d !== values.weekDay)
                      .join(" ,")}`
                  );
                }
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setValues,
            }) => {
              let currentDepartment = recommendation.departments.find(
                (d) => d.department_id === values.department
              );

              return (
                <form
                  noValidate
                  onSubmit={handleSubmit}
                  className="space-y-8 divide-y h-[60vh] divide-gray-200"
                >
                  <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="patientID"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Patient
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0 flex items-center">
                        <div className="flex-1 relative">
                          {/* Loading Spinner */}
                          {allPatients.loading && (
                            <span className="absolute top-1/2 -translate-y-1/2 -left-8 inline-block text-indigo-500">
                              <FiLoader
                                className="block animate-spin"
                                size={24}
                              />
                            </span>
                          )}

                          {/* Select Component */}
                          <Select
                            onInputChange={(e) => {
                              onFilterChange(e);
                            }}
                            options={[
                              ...allPatients.data.map((p) => ({
                                value: p.id,
                                label: `${p?.firstName?.trim() || ""} ${
                                  p?.lastName?.trim() || ""
                                } - ${p?.patient_id || ""}`,
                              })),
                              { label: "loading...", value: "loading..." },
                            ].filter(
                              (option) =>
                                allPatients.loading ||
                                option.label !== "loading..."
                            )}
                            value={values.patient}
                            onChange={(e) => {
                              const selectpatient = patient.list.find(
                                (p) => p.id === Number(e.value)
                              );
                              setValues((prev) => ({
                                ...prev,
                                patient: e,
                                locationError: selectpatient?.location_id
                                  ? ""
                                  : "Location Id is not Available",
                                patientID: Number(e.value),
                                location_id: selectpatient?.location_id,
                              }));

                              if (e) {
                                dispatch(
                                  getPatientRecommandationWithId(e.value)
                                );
                              } else {
                                dispatch(clearPatientRecommendation());
                              }
                            }}
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                            classNamePrefix="select"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="typeOfAppointment"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Type of Appointment
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select
                          required
                          id="typeOfAppointment"
                          name="typeOfAppointment"
                          value={values.typeOfAppointment}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                        >
                          <option value="">Select a Type</option>
                          <option value="ONLINE">Online</option>
                          <option value="OFFLINE">Offline</option>
                        </select>
                        {touched.typeOfAppointment &&
                          errors.typeOfAppointment && (
                            <p className="text-red-600 text-sm">
                              {errors.typeOfAppointment}
                              NO_DISCOUNT{" "}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="recommendation_program"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Recommended program
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      {recommendation.loading ? (
                        <span className="text-sm text-indigo-700">
                          Fetching recommendations for patient
                        </span>
                      ) : recommendation.error !== null ? (
                        <span className="text-sm text-red-700">
                          {recommendation.error}
                        </span>
                      ) : (
                        <select
                          id="recommendation_program"
                          name="rp_id"
                          disabled={recommendation.list.length === 0}
                          value={values.rp_id}
                          onChange={(e) => {
                            let program = recommendation.list.find(
                              (p) => p.id == e.target.value
                            );
                            handleChange(e);
                            if (e.target.value) {
                              setValues({
                                ...values,
                                rp_id: e.target.value.toString(),
                                sProgramId: program.programMaster.id,
                              });
                              setRpId(
                                e.target.selectedOptions[0].getAttribute(
                                  "data-sec"
                                )
                              );
                              dispatch(
                                getDepartmentsByProgramId(e.target.value)
                              );
                            } else {
                              dispatch(clearDepartment());
                              setRpId(null);
                            }
                          }}
                          onBlur={handleBlur}
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                        >
                          <option>Select a Program</option>
                          {recommendation.list.map((p, index) => (
                            <option key={index} value={p.id}>
                              {p.programMaster.name}
                            </option>
                          ))}
                        </select>
                      )}
                      {touched.rp_id && errors.rp_id && (
                        <p className="text-red-600 text-sm">{errors.rp_id}</p>
                      )}
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="discount_type"
                      className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                    >
                      Discount Type
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <select
                        required
                        id="discount_type"
                        name="discount_type"
                        value={values.discount_type}
                        onChange={(e) => {
                          handleChange(e);
                          setValues({
                            ...values,
                            discount_type: e.target.value,
                            discount_percent: "",
                          });
                        }}
                        onBlur={handleBlur}
                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                      >
                        <option value="SPONSORSHIP">SPONSORSHIP</option>
                        <option value="CONCESSION">CONCESSION</option>
                        <option value="DISCOUNT">DISCOUNT</option>
                        <option value="NO_DISCOUNT">NO_DISCOUNT</option>
                      </select>
                    </div>
                  </div>
                  {(values.discount_type !== "" ||
                    values.discount_type !== "NO_DISCOUNT") &&
                  values.discount_type === "CONCESSION" ? (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="discount_type"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Sponsorship
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select
                          required
                          id="discount_percent"
                          name="discount_percent"
                          value={values.discount_percent}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                        >
                          <option value="0">Select Sponsor</option>
                          <option value="8">8%</option>
                          <option value="10">10%</option>
                          <option value="20">20%</option>
                        </select>
                      </div>
                    </div>
                  ) : (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                      <label
                        htmlFor="therapist_id"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Discount Percent
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="number"
                          name="discount_percent"
                          id="discount_percent"
                          autoComplete="discount_percent"
                          disabled={values.discount_type === "NO_DISCOUNT"}
                          value={values.discount_percent}
                          onChange={handleChange}
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                        />
                      </div>
                    </div>
                  )}
                  {recommendation.dTabs.length > 0 && (
                    <>
                      <div>
                        <div className="sm:hidden">
                          <label htmlFor="tabs" className="sr-only">
                            Select a tab
                          </label>
                          <select
                            id="tabs"
                            name="department"
                            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            value={values.department}
                            onChange={(e) => {
                              handleChange(e);
                              setValues({
                                ...values,
                                department: e.target.value,
                                // location_id: "",
                                weekDays: [],
                                weekDay: "",
                                lockDates: [],
                                lockTimeSlots: [],
                                requireSlots: "",
                                timeSlot: "",
                              });
                              dispatch(
                                changeActiveDepartmentTab(e.target.value)
                              );
                            }}
                            onBlur={handleBlur}
                          >
                            <option value="">Select a department</option>
                            {recommendation.dTabs.map((tab, idx) => (
                              <option key={idx} value={tab.indicator}>
                                {tab.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="hidden sm:block">
                          <div className="border-b border-gray-200">
                            <nav
                              className="-mb-px flex space-x-8"
                              aria-label="Tabs"
                            >
                              {recommendation.dTabs.length > 0 &&
                                recommendation.dTabs.map((tab) => (
                                  <span
                                    key={tab.name}
                                    className={classNames(
                                      tab.current
                                        ? "border-indigo-500 text-indigo-600"
                                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                      "whitespace-nowrap py-4 cursor-pointer px-1 border-b-2 font-medium text-sm"
                                    )}
                                    aria-current={
                                      tab.current ? "page" : undefined
                                    }
                                    onClick={() => {
                                      setValues({
                                        ...values,
                                        department: tab.indicator,
                                        // location_id: "",
                                        weekDays: [],
                                        weekDay: "",
                                        lockDates: [],
                                        lockTimeSlots: [],
                                        requireSlots: "",
                                        timeSlot: "",
                                        days: [],
                                        dates: [],
                                      });
                                      dispatch(
                                        changeActiveDepartmentTab(tab.indicator)
                                      );
                                    }}
                                  >
                                    {tab.name}
                                  </span>
                                ))}
                              <div className="w-full h-full mt-2 flex items-center justify-end">
                                {currentDepartment && (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setRenderOldBookingModal((prev) => {
                                        return {
                                          ...prev,
                                          state: true,
                                        };
                                      });
                                      getBookings({
                                        patient_id: values.patientID,
                                        recommended_program_id: Number(
                                          values.rp_id
                                        ),
                                      });
                                    }}
                                    className="inline-flex items-center rounded-md border border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium  text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    Older Bookings
                                  </button>
                                )}
                              </div>
                            </nav>
                          </div>
                        </div>
                        {currentDepartment && (
                          <div className="mt-5">
                            <>
                              <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                {`${toWord(currentDepartment?.frequency)} A ${
                                  currentDepartment?.frequency_type
                                }`}
                              </label>
                              <>
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                                  <label
                                    htmlFor="location_id"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                  >
                                    Location
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <select
                                      id="location_id"
                                      name="location_id"
                                      value={values.location_id}
                                      onClick={() => {
                                        if (values.location_id) {
                                          setValues((prev) => ({
                                            ...prev,
                                            locationError: "",
                                          }));
                                        } else if (!values.location_id) {
                                          setValues((prev) => ({
                                            ...prev,
                                            locationError:
                                              "Location id not available in this patient",
                                          }));
                                        }
                                      }}
                                      disabled={location.list?.length === 0}
                                      onChange={(l) => {
                                        handleChange(l);
                                      }}
                                      onBlur={handleBlur}
                                      className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                                    >
                                      <option>Select a Location</option>
                                      {location?.list?.map((loc, index) => (
                                        <option key={index} value={loc.id}>
                                          {`${loc.name}`}
                                        </option>
                                      ))}
                                    </select>
                                    {touched.location_id &&
                                      errors.location_id && (
                                        <p className="text-red-600 text-sm">
                                          {errors.location_id}
                                        </p>
                                      )}
                                    <span className="text-sm text-red-500">
                                      {values.locationError}
                                    </span>
                                  </div>
                                </div>
                                {values.location_id && (
                                  <>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                                      <label
                                        htmlFor="therapist_id"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                      >
                                        Search by Time Period
                                      </label>
                                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select
                                          id="time_day"
                                          name="time_day"
                                          value={values.time_day}
                                          onBlur={handleBlur}
                                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                                        >
                                          <option value="">
                                            Select a Time Period
                                          </option>
                                          <option value="first_half">
                                            First Half
                                          </option>
                                          <option value="second_half">
                                            Second Half
                                          </option>
                                        </select>
                                        {touched.therapist_id &&
                                          errors.therapist_id && (
                                            <p className="text-red-600 text-sm">
                                              {errors.therapist_id}
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                                      <label
                                        htmlFor="dateSelect"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                      >
                                        Search therapist by days
                                      </label>
                                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <Select
                                          isMulti
                                          value={values.days}
                                          name="dateSelect"
                                          options={[
                                            {
                                              label: "Sunday",
                                              value: "SUNDAY",
                                            },
                                            {
                                              label: "Monday",
                                              value: "MONDAY",
                                            },
                                            {
                                              label: "Tuesday",
                                              value: "TUESDAY",
                                            },
                                            {
                                              label: "Wednesday",
                                              value: "WEDNESDAY",
                                            },
                                            {
                                              label: "Thursday",
                                              value: "THURSDAY",
                                            },
                                            {
                                              label: "Friday",
                                              value: "FRIDAY",
                                            },
                                            {
                                              label: "Saturday",
                                              value: "SATURDAY",
                                            },
                                          ]}
                                          onChange={(e) => {
                                            setValues({
                                              ...values,
                                              days: e,
                                              therapist_id: "",
                                            });
                                            dispatch(
                                              getAllTherapistInDepartmentByDays(
                                                currentDepartment?.department_id,
                                                e.map((e) => e.value),
                                                values.location_id
                                              )
                                            );
                                          }}
                                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                                          classNamePrefix="select"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                                      <label
                                        htmlFor="therapist_id"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                      >
                                        Therapist
                                      </label>
                                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select
                                          id="therapist_id"
                                          name="therapist_id"
                                          value={values.therapist_id}
                                          disabled={
                                            therapist?.list?.length === 0
                                          }
                                          onChange={(e) => {
                                            // handleChange(e);
                                            setValues({
                                              ...values,
                                              therapist_id: e.target.value,
                                              dates: [],
                                              weekDay: "",
                                              weekDays: [],
                                              lockDates: [],
                                            });
                                            if (e.target.value) {
                                              getAvailableDates(e.target.value);
                                            } else {
                                              setAvailDates([]);
                                            }
                                          }}
                                          onBlur={handleBlur}
                                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                                        >
                                          <option value="">
                                            Select a Therapist
                                          </option>
                                          {therapist?.list?.map((t, index) => (
                                            <option key={index} value={t.id}>
                                              {`${t.first_name} ${t.last_name}`}
                                            </option>
                                          ))}
                                        </select>
                                        {touched.therapist_id &&
                                          errors.therapist_id && (
                                            <p className="text-red-600 text-sm">
                                              {errors.therapist_id}
                                            </p>
                                          )}
                                      </div>
                                    </div>

                                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 mb-5  sm:pt-5">
                                      <label
                                        htmlFor="date"
                                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                      >
                                        Date
                                      </label>
                                      <div className="mt-1 w-full ">
                                        <Calendar
                                          multiple
                                          name="date"
                                          format="YYYY-MM-DD"
                                          sort
                                          minDate={new Date()}
                                          plugins={[<DatePanel />]}
                                          mapDays={({ date }) => {
                                            // console.log(date);
                                            let props = {};
                                            let cdate = `${date.year}-${
                                              date.month < 10
                                                ? `0${date.month}`
                                                : date.month
                                            }-${
                                              date.day < 10
                                                ? `0${date.day}`
                                                : date.day
                                            }`;

                                            if (values.dates.includes(cdate)) {
                                              props.className =
                                                "highlight highlight-green";
                                              if (!availDates.includes(cdate)) {
                                                props.className =
                                                  "highlight highlight-red";
                                              }
                                            }

                                            if (
                                              availDates.length > 0 &&
                                              !availDates.includes(cdate) &&
                                              !values.dates.includes(cdate)
                                            )
                                              props.disabled = true;
                                            else {
                                              props.disabled = false;
                                            }
                                            return props;
                                          }}
                                          value={values.dates.map((d) =>
                                            moment(d, "YYYY-MM-DD").toDate()
                                          )}
                                          onChange={async (value) => {
                                            if (value) {
                                              getTimeSlots(
                                                values.therapist_id,
                                                value
                                                  .map((v) =>
                                                    v
                                                      .format("YYYY-MM-DD")
                                                      .toString()
                                                  )
                                                  .filter((date) => {
                                                    const isHoliday =
                                                      hoidayList.data.find(
                                                        (y) =>
                                                          moment(y.date).format(
                                                            "DD/MM/YYYY"
                                                          ) ===
                                                          moment(date).format(
                                                            "DD/MM/YYYY"
                                                          )
                                                      );
                                                    if (isHoliday) {
                                                      return false;
                                                    } else {
                                                      return true;
                                                    }
                                                  }),
                                                values.typeOfAppointment
                                              );
                                            }

                                            setValues({
                                              ...values,
                                              dates: value
                                                .map((v) =>
                                                  v
                                                    .format("YYYY-MM-DD")
                                                    .toString()
                                                )
                                                .filter((date) => {
                                                  const isHoliday =
                                                    hoidayList.data.find(
                                                      (y) =>
                                                        moment(y.date).format(
                                                          "DD/MM/YYYY"
                                                        ) ===
                                                        moment(date).format(
                                                          "DD/MM/YYYY"
                                                        )
                                                    );
                                                  if (isHoliday) {
                                                    return false;
                                                  } else {
                                                    return true;
                                                  }
                                                }),
                                            });
                                            const isHolidayd = value
                                              .map((v) =>
                                                v
                                                  .format("YYYY-MM-DD")
                                                  .toString()
                                              )
                                              .filter((x) => {
                                                const isHoliday =
                                                  hoidayList.data.find(
                                                    (y) =>
                                                      moment(y.date).format(
                                                        "DD/MM/YYYY"
                                                      ) ===
                                                      moment(x.date).format(
                                                        "DD/MM/YYYY"
                                                      )
                                                  );
                                                if (isHoliday) {
                                                  return true;
                                                }
                                                return false;
                                              });
                                            console.log(isHolidayd);
                                            if (isHolidayd.length > 0) {
                                              toast.error(
                                                "Selected Dates include Holidays"
                                              );
                                            }
                                            if (isHolidayd.length === 0) {
                                              try {
                                                let dates = value.map(
                                                  (date) => {
                                                    return date
                                                      .format("YYYY-MM-DD")
                                                      .toString();
                                                  }
                                                );
                                                let department =
                                                  recommendation.departments.find(
                                                    (t) =>
                                                      t.department_id ===
                                                      values.department
                                                  );
                                                if (
                                                  department?.frequency >
                                                  dates.length
                                                ) {
                                                  toast.error(
                                                    `Frequency of selected department is ${department?.frequency} times a ${department.frequency_type}`
                                                  );
                                                } else if (
                                                  department?.frequency ==
                                                  dates.length
                                                ) {
                                                  console.log("dates", dates);
                                                  const body = {
                                                    patient_id: Number(
                                                      values.patientID
                                                    ),
                                                    therapist_id: Number(
                                                      values.therapist_id
                                                    ),
                                                    department_id: Number(
                                                      values.department
                                                    ),
                                                    location_id: Number(
                                                      values.location_id
                                                    ),
                                                    recommended_program_id:
                                                      Number(values.rp_id),
                                                    date: dates,
                                                    appointment_type:
                                                      values.appointment_type,
                                                  };
                                                  console.log(
                                                    "dates",
                                                    body.date
                                                  );
                                                  let headers = dispatch(
                                                    getHeaders()
                                                  );
                                                  const response =
                                                    await axios.post(
                                                      `${API}/api/therapist/appointment/month-date`,
                                                      body,
                                                      { headers }
                                                    );
                                                  const { data, status } =
                                                    response;
                                                  if (status === 200) {
                                                    let temp_days = [];
                                                    data.data.forEach(
                                                      (date) => {
                                                        let weekDay = moment(
                                                          date,
                                                          "YYYY-MM-DD"
                                                        ).format("dddd");
                                                        if (
                                                          !temp_days.includes(
                                                            weekDay
                                                          )
                                                        ) {
                                                          temp_days.push(
                                                            weekDay
                                                          );
                                                        }
                                                      }
                                                    );

                                                    setValues({
                                                      ...values,
                                                      availtable: false,
                                                      dates: data.data.filter(
                                                        (date) => {
                                                          const isHoliday =
                                                            hoidayList.data.find(
                                                              (y) =>
                                                                moment(
                                                                  y.date
                                                                ).format(
                                                                  "DD/MM/YYYY"
                                                                ) ===
                                                                moment(
                                                                  date
                                                                ).format(
                                                                  "DD/MM/YYYY"
                                                                )
                                                            );
                                                          if (isHoliday) {
                                                            return false;
                                                          } else {
                                                            return true;
                                                          }
                                                        }
                                                      ),
                                                      weekDays: temp_days,
                                                    });
                                                  }
                                                }
                                              } catch (error) {
                                                toast.error(error);
                                              }
                                            } else {
                                              setValues({
                                                ...values,
                                                dates: [],
                                                weekDays: [],
                                                weekDay: "",
                                                lockDates: [],
                                                lockTimeSlots: [],
                                                requireSlots: "",
                                                timeSlot: "",
                                                availtable: false,
                                              });
                                            }
                                          }}
                                        />
                                        <button
                                          type="button"
                                          className="mt-3 inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                          onClick={() => {
                                            setValues({
                                              ...values,
                                              dates: [],
                                              weekDays: [],
                                              weekDay: "",
                                              lockDates: [],
                                              lockTimeSlots: [],
                                              requireSlots: "",
                                              timeSlot: "",
                                              availtable: false,
                                            });
                                          }}
                                        >
                                          Reset
                                        </button>
                                      </div>
                                    </div>
                                    {values.therapist_id &&
                                      values.dates.length > 0 && (
                                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                                          <label
                                            htmlFor="therapist_id"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                          >
                                            Availability
                                          </label>
                                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                                            <AvailabilityTable
                                              enable={values.availtable}
                                              setValues={setValues}
                                              values={values}
                                              therapist_id={values.therapist_id}
                                              appointment_type={
                                                values.typeOfAppointment
                                              }
                                              allDates={values.dates}
                                              Theaders={values.weekDays}
                                            />
                                          </div>
                                        </div>
                                      )}

                                    <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                      <div className="mt-1 sm:mt-0">
                                        <select
                                          name="weekDay"
                                          value={values.weekDay}
                                          onChange={(e) => {
                                            // handleChange(e);
                                            // setValues({ ...values, weekDay: e.target.value})
                                            let lockDates = [];
                                            if (e.target.value) {
                                              lockDates = values.dates
                                                .filter(
                                                  (date) =>
                                                    moment(
                                                      date,
                                                      "YYYY-MM-DD"
                                                    ).format("dddd") ==
                                                    e.target.value
                                                )

                                                .map((date) => ({
                                                  value: date,
                                                  label: date,
                                                }))
                                                .filter((date) => {
                                                  const isHoliday =
                                                    hoidayList.data.find(
                                                      (y) =>
                                                        moment(y.date).format(
                                                          "DD/MM/YYYY"
                                                        ) ===
                                                        moment(
                                                          date.value
                                                        ).format("DD/MM/YYYY")
                                                    );
                                                  if (isHoliday) {
                                                    console.log(
                                                      "date",
                                                      isHoliday
                                                    );
                                                    return false;
                                                  } else {
                                                    return true;
                                                  }
                                                });

                                              console.log(
                                                "lockDates",
                                                lockDates
                                              );

                                              // TODO: get time slots for dates
                                              getTimeSlots(
                                                values.therapist_id,
                                                lockDates
                                                  .map((d) => d.value)
                                                  .filter((date) => {
                                                    const isHoliday =
                                                      hoidayList.data.find(
                                                        (y) =>
                                                          moment(y.date).format(
                                                            "DD/MM/YYYY"
                                                          ) ===
                                                          moment(date).format(
                                                            "DD/MM/YYYY"
                                                          )
                                                      );
                                                    if (isHoliday) {
                                                      return false;
                                                    } else {
                                                      return true;
                                                    }
                                                  }),
                                                values.typeOfAppointment,
                                                values.location_id,
                                                values.department,
                                                values.days
                                              );
                                            } else {
                                              setAvailTimeSlots([]);
                                            }
                                            setAvailTimeSlots([]);
                                            setValues({
                                              ...values,
                                              weekDay: e.target.value,
                                              lockDates,
                                              timeSlot: "",
                                            });
                                          }}
                                          disabled={
                                            values.weekDays.length === 0
                                          }
                                          onBlur={handleBlur}
                                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                                        >
                                          <option value="">Select a Day</option>
                                          {values.weekDays.map((day, index) => (
                                            <option key={index} value={day}>
                                              {day}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      {console.log("availTimeSlotsv", values)}
                                      <div className="mt-1 sm:mt-0 min-w-[220px]">
                                        <Select
                                          isMulti
                                          value={values.lockDates}
                                          name="lockDates"
                                          options={values.dates
                                            .filter((date) => {
                                              if (values.weekDay)
                                                return (
                                                  moment(
                                                    date,
                                                    "YYYY-MM-DD"
                                                  ).format("dddd") ==
                                                  values.weekDay
                                                );
                                              else return true;
                                            })
                                            .filter((date) => {
                                              const isHoliday =
                                                hoidayList.data.find(
                                                  (y) =>
                                                    moment(y.date).format(
                                                      "DD/MM/YYYY"
                                                    ) ===
                                                    moment(date).format(
                                                      "DD/MM/YYYY"
                                                    )
                                                );

                                              console.log("date", isHoliday);

                                              if (isHoliday) {
                                                return false;
                                              } else {
                                                return true;
                                              }
                                            })
                                            .map((date) => {
                                              return {
                                                value: date,
                                                label: date,
                                              };
                                            })}
                                          onChange={(e) => {
                                            getTimeSlots(
                                              values.therapist_id,
                                              e.map((d) => d.value),
                                              values.typeOfAppointment
                                            );
                                            setValues({
                                              ...values,
                                              lockDates: e,
                                            });
                                          }}
                                          className="basic-multi-select w-auto"
                                          classNamePrefix="select"
                                        />
                                      </div>
                                      <div className="mt-1  sm:mt-0">
                                        <select
                                          id="timeSlot"
                                          name="timeSlot"
                                          value={values.timeSlot}
                                          onChange={handleChange}
                                          disabled={availTimeSlots.length === 0}
                                          onBlur={handleBlur}
                                          required
                                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                                        >
                                          <option value="">
                                            Select a time slot
                                          </option>
                                          {console.log(availTimeSlots)}
                                          {availTimeSlots.map((time, index) => (
                                            <option
                                              key={index}
                                              value={time.value}
                                            >
                                              {time.label}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className="flex justify-start">
                                        <div
                                          onClick={() => {
                                            let lockDates =
                                              values.lockDates.map((date) => ({
                                                date: date.value,
                                                time_slot: values.timeSlot,
                                              }));
                                            let totalSlots =
                                              currentDepartment.frequency_type ===
                                              "WEEK"
                                                ? currentDepartment.frequency *
                                                  4 *
                                                  3
                                                : currentDepartment.frequency *
                                                  1 *
                                                  3;

                                            console.log(totalSlots);
                                            toast.success(
                                              `${values.weekDay} ${values.timeSlot} for ${currentDepartment.department.name} is locked`
                                            );
                                            setValues({
                                              ...values,
                                              requireSlots: totalSlots,
                                              _department:
                                                currentDepartment.department
                                                  .name,
                                              lockTimeSlots: updateLock(
                                                values.lockTimeSlots,
                                                lockDates
                                              ),
                                            });
                                            setTimeout(() => {
                                              myRef.current.scrollIntoView({
                                                behavior: "smooth",
                                                block: "start",
                                              });
                                            }, 500);
                                          }}
                                          className="ml-3 cursor-pointer inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                          Lock
                                        </div>
                                      </div>
                                    </div>

                                    {values.lockTimeSlots.length > 0 && (
                                      <>
                                        <div className="w-full h-max">
                                          <p>
                                            {" "}
                                            {values.lockTimeSlots.length} DATES
                                            BOOKED OUT OF{" "}
                                            {currentDepartment.frequency_type ===
                                            "WEEK"
                                              ? currentDepartment.frequency *
                                                4 *
                                                3
                                              : currentDepartment.frequency *
                                                1 *
                                                3}
                                          </p>
                                        </div>
                                        <div className="w-[50%]">
                                          <DynamicTable
                                            data={values.lockTimeSlots}
                                          />
                                        </div>
                                        <button
                                          ref={myRef}
                                          className="inline-flex mt-4 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                          type="submit"
                                        >
                                          Submit
                                        </button>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            </>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </form>
              );
            }}
          </Formik>
        )}
        {programTabs.find((t) => t.current).indicator === "ev" && <ByEv />}
      </div>
    </div>
  );
}

export default AddTherapistAppointment;

/***
 *
 * Legacy code
 */
// export default function AddTherapistApointment() {
//   const [addPatientModal, setaddPatientModal] = useState(false);
//   const [daterange, setDateRange] = useState({
//     data: [],
//     loading: false,
//   });
//   const [availDates, setAvailDates] = useState({
//     data: [],
//     loading: false,
//   });
//   const [timeSlots, setTimeslots] = useState({
//     data: [],
//     loading: false,
//   });
//   const [date_time, set_date_time] = useState([]);

//   const [current_date_time, set_current_date_time] = useState({
//     date: "null",
//     time_slot: "null",
//   });
//   const [date_day, set_date_day] = useState({ current_day: "", all_day: [] });

//   const [times, settime] = useState({
//     data: [],
//     loading: false,
//   });
//   const [sessions_required, set_session_required] = useState(0);

//   const navigate = useNavigate();
//   // const getTimeSlots = async (date) => {
//   //   const headers = dispatch(getHeaders());
//   //   try {
//   //     setTimeslots({ ...timeSlots, loading: true });
//   //     if (date !== "invalid") {
//   //       const body = {
//   //         therapist_id: values.therapist_id,
//   //         location_id: values.location_id,
//   //         date: moment(date).format("YYYY-MM-DD"),
//   //         appointment_type: "ONLINE",
//   //       };
//   //       const response = await axios.post(
//   //         `${Api}/api/therapist/appointment/get-appointment-time-slots`,
//   //         body,
//   //         {
//   //           headers,
//   //         }
//   //       );
//   //       const { data, status } = response;
//   //       // console.log(response);
//   //       if (status === 200) {
//   //         //
//   //         // set_current_date_time({date:data.data ? data.data : [],time_slot:null})
//   //         // settime({ ...times, data: data.data ? data.data : [] , loading: false });
//   //       //   const response = await axios.post(
//   //       //     `${Api}/api/therapist/appointment/get-clustered-time-slots`,
//   //       //     {
//   //       //   therapist_id: values.therapist_id,
//   //       //   location_id: values.location_id,
//   //       //   dates: data.data,
//   //       //   appointment_type: "ONLINE",
//   //       // },
//   //       //     {
//   //       //       headers,
//   //       //     }
//   //       //   );
//   //         console.log(data.data,'dates to select');
//   //       }
//   //     }
//   //   } catch (error) {
//   //     console.log(error);
//   //   }
//   // };

//     onSubmit: (values, action) => {
//       const time = times?.data[values.timeSlots];
//       if (timeSlots.data.length != date_time.length) {
//         toast.warn("please Lock time for all dates");
//       }
//       const body = {
//         patient_id: parseInt(values.patientID),
//         department_id: parseInt(values.department),
//         recommended_program_id: parseInt(values.rp_id),
//         patient_main_recommendation_id: parseInt(rp_main_id),
//         location_id: parseInt(values.location_id),
//         therapist_id: parseInt(values.therapist_id),
//         date: date_time,
//         booking_type: "NORMAL",
//       };
//       add(body);
//     },
//   });
//   async function add(body) {
//     const headers = dispatch(getHeaders());
//     try {
//       const res = await axios.post(
//         `${process.env.REACT_APP_API_URL}/api/therapist/appointment/create`,
//         body,
//         {
//           headers: headers,
//         }
//       );
//       const { data, status } = res;
//       if (status === 200) {
//         reset_form();
//         toast.success("Appointment has been saved successfully");
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   }

//   const Api = process.env.REACT_APP_API_URL;
//   async function handleDateChange(e) {
//     const headers = dispatch(getHeaders());
//     if (e.length > 0) {
//       const response2 = await axios.post(
//         `${Api}/api/therapist/appointment/get-appointment-time-slots`,
//         {
//           therapist_id: values.therapist_id,
//           date: e,
//           appointment_type: values.typeOfAppointment,
//         },
//         {
//           headers,
//         }
//       );
//       console.log({ response2: response2 }, e);
//       // const response = await axios.post(
//       //   `${Api}/api/therapist/appointment/get-clustered-time-slots`,
//       //   {
//       //     therapist_id:values.therapist_id,
//       //     dates:e,
//       //     appointment_type:values.typeOfAppointment
//       //   },
//       //   {
//       //     headers,
//       //   }
//       // );
//       const { data, status } = response2;
//       if (status == 200) {
//         settime({ ...times, data: data.data ? data.data : [], loading: false });
//       }
//     }
//   }
//   function handleTimeSlot(e) {
//     set_current_date_time({ ...current_date_time, time_slot: e.target.value });
//   }

//   useEffect(() => {
//     dispatch(getAllPatients());
//     getAllLocation();
//     if (AppointmentId) {
//       const headers = dispatch(getHeaders());

//       const res = axios.get(
//         `${Api}/api/therapist/appointment/appointment-session/${AppointmentId}`,
//         {
//           headers: headers,
//         }
//       );
//       const { data, status } = res;
//       if (data) {
//         console.log("getdata", data.data);
//         setinitialValues({ ...initialValues });
//       }
//     }
//   }, [AppointmentId]);

//   useEffect(() => {
//     getTherapistsDates(values.therapist_id);
//   }, [values.therapist_id]);

//   const getTherapistsDates = async (id) => {
//     const headers = dispatch(getHeaders());
//     setAvailDates({ ...availDates, loading: true });
//     if (parseInt(id) > 0) {
//       const response = await axios.get(
//         `${Api}/api/therapist/appointment/get-appointment-dates/${id}`,
//         { headers }
//       );
//       const { data } = await response;
//       console.log("available dates", data.data);
//       set_session_required(data.data.length);
//       setAvailDates({ ...availDates, data: data.data, loading: false });
//     }
//   };

//   function reset_form() {
//     // resetForm()   ;
//     // setTabs([]);

//     setDateRange({
//       data: [],
//       loading: false,
//     });
//     setAvailDates({
//       data: [],
//       loading: false,
//     });
//     setTimeslots({
//       data: [],
//       loading: false,
//     });
//     set_date_time([]);
//     set_current_date_time({ date: [], time_slot: "null" });
//     settime({
//       data: [],
//       loading: false,
//     });
//     setRPId("");

//     set_date_sel(true);
//   }

//                           {values.location_id ? (
//                             <>
//                               <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
//                                 <label
//                                   htmlFor="therapist_id"
//                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
//                                 >
//                                   Select Days
//                                 </label>
//                                 <div className="mt-1 sm:col-span-2 sm:mt-0">
//                                   <Select
//                                     isMulti
//                                     // value={current_date_time?.date?.length>0 ? current_date_time?.date?.map(val=>{ return {value:val,label:val}}):[]}
//                                     name="dateSelect"
//                                     options={[
//                                       { label: "Sunday", value: "SUNDAY" },
//                                       { label: "Monday", value: "MONDAY" },
//                                       { label: "Tuesday", value: "TUESDAY" },
//                                       {
//                                         label: "Wednesday",
//                                         value: "WEDNESDAY",
//                                       },
//                                       { label: "Thursday", value: "THURSDAY" },
//                                       { label: "Friday", value: "FRIDAY" },
//                                       { label: "Saturday", value: "SATURDAY" },
//                                     ]}
//                                     onChange={(e) => {
//                                       console.log(tabs);
//                                       getTherapistss(
//                                         tabs.filter((t) => t.current)[0]
//                                           .department_id,
//                                         e.map((e) => e.value)
//                                       );
//                                     }}
//                                     className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
//                                     classNamePrefix="select"
//                                   />
//                                 </div>
//                               </div>
//                               <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
//                                 <label
//                                   htmlFor="therapist_id"
//                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
//                                 >
//                                   Therapist
//                                 </label>
//                                 <div className="mt-1 sm:col-span-2 sm:mt-0">
//                                   <select
//                                     id="therapist_id"
//                                     name="therapist_id"
//                                     value={values.therapist_id}
//                                     disabled={
//                                       therapistLists?.data?.length === 0
//                                     }
//                                     onChange={handleChange}
//                                     onBlur={handleBlur}
//                                     className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
//                                   >
//                                     <option value="all">
//                                       Select a Therapist
//                                     </option>
//                                     {therapistLists?.data?.map(
//                                       (therapist, index) => (
//                                         <option
//                                           key={index}
//                                           value={therapist.id}
//                                         >
//                                           {`${therapist.first_name} ${therapist.last_name}`}
//                                         </option>
//                                       )
//                                     )}
//                                   </select>
//                                   {errors.therapist_id &&
//                                   touched.therapist_id ? (
//                                     <p className="text-red-600 text-sm">
//                                       {errors.therapist_id}
//                                     </p>
//                                   ) : null}
//                                 </div>
//                               </div>
//                               <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 mb-5  sm:pt-5">
//                                 <label
//                                   htmlFor="dob"
//                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
//                                 >
//                                   Date
//                                 </label>
//                                 <div className="mt-1">
//                                   <Calendar
//                                     multiple
//                                     name="date"
//                                     minDate={new Date()}
//                                     plugins={[<DatePanel />]}
//                                     mapDays={({ date }) => {
//                                       let props = {};
//                                       let cdate = `${date.year}-${
//                                         date.month < 10
//                                           ? `0${date.month}`
//                                           : date.month
//                                       }-${
//                                         date.day < 10
//                                           ? `0${date.day}`
//                                           : date.day
//                                       }`;

//                                       if (!availDates.data.includes(cdate))
//                                         props.disabled = true;

//                                       if (timeSlots.data.includes(cdate)) {
//                                         if (availDates.data.includes(cdate)) {
//                                           props.className =
//                                             "highlight highlight-green";
//                                         } else {
//                                           {
//                                             props.className =
//                                               "highlight highlight-red";
//                                           }
//                                         }
//                                         props.disabled = false;
//                                       }
//                                       return props;
//                                     }}
//                                     value={
//                                       !date_sel
//                                         ? timeSlots.data.map((sin) =>
//                                             moment(sin, "YYYY-MM-DD").toDate()
//                                           )
//                                         : values.date.length > 0
//                                         ? values.date.map((sin) =>
//                                             moment(sin, "YYYY-MM-DD").toDate()
//                                           )
//                                         : null
//                                     }
//                                     onChange={(value, e) => {
//                                       value = value.map((val) =>
//                                         val.format("YYYY-MM-DD").toString()
//                                       );
//                                       // getTimeSlots(value[0]);
//                                       if (date_sel) {
//                                         getDates(value);
//                                       } else {
//                                         setTimeslots({
//                                           ...timeSlots,
//                                           data: value,
//                                           loading: false,
//                                         });
//                                       }

//                                       if (values.patientID) {
//                                         setValues({
//                                           ...values,
//                                           date: value,
//                                         });
//                                         setFieldValue("date", value);
//                                       }
//                                     }}
//                                   />
//                                 </div>
//                               </div>
//                               <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
//                                 {console.log(date_day)}
//                                 <div className="mt-1 sm:mt-0">
//                                   <select
//                                     id="daySelect"
//                                     name="daySelect"
//                                     value={date_day?.current_day}
//                                     onChange={(e) => {
//                                       // if (e.target.value) {
//                                       set_date_day({
//                                         ...date_day,
//                                         current_day: e.target.value,
//                                       });
//                                       set_current_date_time({
//                                         date: timeSlots?.data,
//                                         time_slot: "null",
//                                       });
//                                       handleDateChange(timeSlots?.data);
//                                       // } else {
//                                       //   set_current_date_time({
//                                       //     date: "null",
//                                       //     time_slot: "null",
//                                       //   });
//                                       // }
//                                     }}
//                                     disabled={date_day?.all_day?.length === 0}
//                                     onBlur={handleBlur}
//                                     className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
//                                   >
//                                     <option value={"null"}>Select a Day</option>
//                                     {date_day?.all_day.length > 0 &&
//                                       date_day?.all_day?.map((day, index) => (
//                                         <option key={index} value={day}>
//                                           {day}
//                                         </option>
//                                       ))}
//                                   </select>
//                                 </div>
//                                 <div className="mt-1 sm:mt-0">
//                                   {console.log(
//                                     timeSlots?.data
//                                       ?.filter(
//                                         (e) =>
//                                           moment(e, "YYYY-MM-DD").format(
//                                             "dddd"
//                                           ) ==
//                                           date_day.current_day.toLowerCase()
//                                       )
//                                       .map((date, index) => date)
//                                   )}
//                                   <Select
//                                     isMulti
//                                     value={
//                                       current_date_time?.date?.length > 0
//                                         ? current_date_time?.date?.map(
//                                             (val) => {
//                                               return { value: val, label: val };
//                                             }
//                                           )
//                                         : []
//                                     }
//                                     name="dateSelect"
//                                     options={timeSlots?.data
//                                       ?.filter(
//                                         (e) =>
//                                           moment(e, "YYYY-MM-DD").format(
//                                             "dddd"
//                                           ) == date_day.current_day
//                                       )
//                                       .map((date, index) => {
//                                         return { value: date, label: date };
//                                       })}
//                                     onChange={(e) => {
//                                       set_current_date_time({
//                                         date: e.map((d) => d.value),
//                                         time_slot: "null",
//                                       });
//                                       handleDateChange(e.map((d) => d.value));
//                                     }}
//                                     className="basic-multi-select"
//                                     classNamePrefix="select"
//                                   />
//                                   {/* <select
//                                     id="dateSelect"
//                                     name="dateSelect"
//                                     value={current_date_time.date}
//                                     onChange={(e)=>{
//                                       set_current_date_time({date:e.target.value,time_slot:'null'})

//                                       handleDateChange(e);}}
//                                     disabled={timeSlots?.data?.length === 0}
//                                     onBlur={handleBlur}
//                                     className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
//                                   >
//                                     <option value={'null'}>
//                                       Select a Date
//                                     </option>
//                                     {timeSlots?.data?.filter(e=>moment(e,'YYYY-MM-DD').format('dddd')==date_day.current_day).map((date, index) => (
//                                       <option class={date_time.find(x=>x.date==date)? 'bg-indigo-100':''}  key={index} value={date}>
//                                         {date}
//                                       </option>
//                                     ))}
//                                   </select> */}
//                                 </div>
//                                 <div className="mt-1  sm:mt-0">
//                                   <select
//                                     id="timeSlots"
//                                     name="timeSlots"
//                                     value={current_date_time.time_slot}
//                                     onChange={handleTimeSlot}
//                                     disabled={times?.data?.length === 0}
//                                     onBlur={handleBlur}
//                                     className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
//                                   >
//                                     <option value={"null"}>
//                                       Select a time slot
//                                     </option>
//                                     {times?.data.map((timeSlot, index) => (
//                                       <option
//                                         class={
//                                           current_date_time.date.length > 0 &&
//                                           date_time.find(
//                                             (x) =>
//                                               x.date ==
//                                               current_date_time.date[0]
//                                           )?.time_slot ==
//                                             timeSlot.start_time +
//                                               "- " +
//                                               timeSlot.end_time
//                                             ? "bg-indigo-100"
//                                             : ""
//                                         }
//                                         key={index}
//                                         value={
//                                           timeSlot.start_time +
//                                           "- " +
//                                           timeSlot.end_time
//                                         }
//                                       >
//                                         {timeSlot.start_time} -{" "}
//                                         {timeSlot.end_time}
//                                       </option>
//                                     ))}
//                                   </select>
//                                 </div>
//                                 <div className="flex justify-start">
//                                   <div
//                                     onClick={() => {
//                                       if (
//                                         current_date_time.date &&
//                                         current_date_time.time_slot &&
//                                         current_date_time.date != "null" &&
//                                         current_date_time.time_slot != "null"
//                                       ) {
//                                         let final_new = [];
//                                         current_date_time.date.forEach(
//                                           (elem) => {
//                                             const index = date_time.findIndex(
//                                               (item) => item.date === elem
//                                             );
//                                             if (index !== -1) {
//                                               const newData = [
//                                                 ...date_time.slice(0, index),
//                                                 {
//                                                   date: elem,
//                                                   time_slot:
//                                                     current_date_time.time_slot,
//                                                 },
//                                                 ...date_time.slice(index + 1),
//                                               ];
//                                               final_new = [
//                                                 ...final_new,
//                                                 ...newData,
//                                               ];
//                                               // console.log(newData);
//                                             } else {
//                                               // If the object with the given ID does not exist, add it
//                                               const newData = [
//                                                 ...date_time,
//                                                 {
//                                                   ...current_date_time,
//                                                   date: elem,
//                                                 },
//                                               ];
//                                               final_new = [
//                                                 ...final_new,
//                                                 ...newData,
//                                               ];
//                                             }
//                                           }
//                                         );
//                                         set_date_time(final_new);

//                                         set_current_date_time({
//                                           date: [],
//                                           time_slot: "null",
//                                         });
//                                       } else {
//                                         toast.warn(
//                                           "please select date and time slot"
//                                         );
//                                       }
//                                     }}
//                                     className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
//                                   >
//                                     Lock
//                                   </div>
//                                 </div>
//                               </div>
//                               <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
//                                 <p>{`${date_time.length} DATES BOOKED OUT OF  ${sessions_required}`}</p>
//                               </div>
//                               <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
//                                 <DynamicTable
//                                   columns={[
//                                     {
//                                       Header: "Date",
//                                       accessor: "date",
//                                     },
//                                     {
//                                       Header: "Time Slot",
//                                       accessor: "time_slot",
//                                     },
//                                   ]}
//                                   data={date_time}
//                                 />
//                               </div>
//                               <div className="mt-5 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
//                                 <label
//                                   htmlFor="typeOfAppointment"
//                                   className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
//                                 >
//                                   Remarks
//                                 </label>
//                                 <div className="mt-1 sm:col-span-2 sm:mt-0">
//                                   <textarea
//                                     type="number"
//                                     name="remark"
//                                     id="remark"
//                                     rows="3"
//                                     autoComplete="off"
//                                     value={values.remark}
//                                     onChange={handleChange}
//                                     onBlur={handleBlur}
//                                     className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
//                                   />
//                                   {errors.timeSlots && touched.timeSlots ? (
//                                     <p className="text-red-600 text-sm">
//                                       {errors.timeSlots}
//                                     </p>
//                                   ) : null}
//                                 </div>
//                               </div>{" "}
//                             </>
//                           ) : (
//                             ""
//                           )}
//                         </>
//                       </>
//                     )}
//                     {/* </>
//                   ))} */}
//                   </div>
//                 </>
//               )}

//               {/* tab Selection ends here */}
//               <div className="pt-5">
//                 <div className="flex justify-end">
//                   <button
//                     type="submit"
//                     className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
//                   >
//                     Submit
//                   </button>
//                 </div>
//               </div>
//             </form>
//           </Formik>
//         )}
//         {ptabs.find((x) => x.indicator === "ev" && x.current === true) && (
//           <ByEv />
//         )}
//       </div>
//     </div>
//   );
// }

// const getDates = async (date) => {
//   try {
//     setTimeslots({ ...timeSlots, loading: true });
//     let filter_date = date.map((sindat) => {
//       return moment(sindat).format("YYYY-MM-DD");
//     });
//     date = [...new Set(filter_date)];
//     const myd = tabs.filter((t) => {
//       if (t.department.id === values.department) {
//         return t;
//       }
//     });
//     if (myd[0].frequency != date.length && date_sel) {
//       toast.error(
//         `frequency of selected department is ${myd[0].frequency} times a ${myd[0].frequency_type}`
//       );
//       return;
//     }
//     if (date !== "invalid" && date_sel) {
//       const body = {
//         patient_id: Number(values.patientID),
//         therapist_id: values.therapist_id,
//         department_id: Number(values.department),
//         location_id: Number(values.location_id),
//         recommended_program_id: Number(values.rp_id),
//         date: date.map((sindat) => {
//           return moment(sindat).format("YYYY-MM-DD");
//         }),
//         appointment_type: "ONLINE",
//       };
//       let headers = dispatch(getHeaders());
//       const response = await axios.post(
//         `${Api}/api/therapist/appointment/month-date`,
//         body,
//         { headers }
//       );
//       const { data, status } = response;
//       if (status === 200) {
//         //
//         set_date_sel(false);
//         let temp_days = [];
//         data.data.forEach((element) => {
//           if (
//             !temp_days.includes(moment(element, "YYYY-MM-DD").format("dddd"))
//           ) {
//             temp_days.push(moment(element, "YYYY-MM-DD").format("dddd"));
//           }
//         });
//         set_date_day({ ...date_day, all_day: temp_days });
//         setTimeslots({ ...timeSlots, data: data.data, loading: false });
//         setFieldValue("date", data.data);
//       }
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };
