import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import RcCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  CheckIcon,
  ChevronUpDownIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import { Menu, Transition, Listbox, Dialog, Combobox } from "@headlessui/react";
import { Modal } from "@mui/material";
import Breadcrumb from "../../../components/Breadcrumb";
import moment from "moment";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";

import {
  getAppointment,
  getAppointmentByDoctor,
  getAvailableSlots,
} from "../../../redux/TherapistAppointment/actions";
import { getAllTherapists } from "../../../redux/therapist/therpist/actions";
import { getAllLocations } from "../../../redux/location/actions";
import Select from "react-select";
import {
  addPatient,
  getAllPatients,
  updatePatient,
} from "../../../redux/patient/actions";
import { getAllDepartments } from "../../../redux/department/actions";
import { Formik, useFormik } from "formik";
import axios from "axios";
import { getHeaders } from "../../../redux/auth/actions";
import * as dateMath from "date-arithmetic";
import {
  AppointmentSchema,
  patientsSchema,
  UpdateAppointmentSchema,
} from "../../../schemas";
import styled from "styled-components";
import PaidOutlined from "@mui/icons-material/PaidOutlined";
import MoneyOffCsredOutlined from "@mui/icons-material/MoneyOffCsredOutlined";
import { AiOutlinePlus } from "react-icons/ai";
import { getAllReferral } from "../../../redux/referral/action";
import { AddRefferalModal } from "../Patients/Addpatients";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { data } from "autoprefixer";
import { useFetch } from "../../../utilities/utilities";
import app_api from "../../../utilities/api";
import { useAsyncDebounce } from "react-table";

const mLocalizer = momentLocalizer(moment);

const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      // backgroundColor: "lightblue",
      minHeight: "50px",
    },
    className:
      "group inset-1 flex flex-col overflow-y-auto p-2 text-xs leading-5",
  });

const CalendarDiv = styled.div`
  & .rbc-month-row {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
`;

const API = process.env.REACT_APP_API_URL;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const initialValues = {
  type: "",
  state: false,
  editId: null,
  data: {
    type: "OFFLINE",
    locationId: "",
    patientId: "",
    doctorId: "",
    date: "",
    time: "",
    payment_type: "FULL",
    remark: "",
    cost: 1000,
    isFollowUp: false,
    advance_payment_amount: null,
    book_type: false,
  },
  apiData: {},
};

export default function Therapist({ localizer = mLocalizer, ...props }) {
  const pages = [{ title: "Appointment", href: "/appointment" }];
  // const container = useRef(null);
  // const containerNav = useRef(null);
  // const containerOffset = useRef(null);
  const [selected, setSelected] = useState({
    id: 0,
    first_name: "Filter by Therapist",
  });
  const [selectedPatient, setSelectedPatient] = useState({
    id: 0,
    first_name: "Filter by Patient",
  });
  const [status, setStatus] = useState({
    id: 0,
    status: "Filter by status",
  });
  const [Program, setProgram] = useState({
    id: 0,
    status: "Filter by Type",
  });
  const [filterLoc, setFilterLoc] = useState({
    id: 0,
    name: "Filter by location",
  });
  const [departments, setDepartment] = useState({
    id: 0,
    name: "Filter by Department",
  });

  const [allPatients, setAllPatients] = useState({
    data: [],
    loading: true,
    error: null,
    query: "",
  });

  useEffect(() => {
    app_api.get(`/api/patient/search?limit=20${allPatients.query ? `&query=${allPatients.query}` : ""}`).then((res) => {
      setAllPatients(prev => ({ ...prev, data: res.data.data, loading: false }));
    });
  }, [allPatients.query]);

  const onFilterChange = useAsyncDebounce((value) => {
    setAllPatients(prev => ({ ...prev, query: value }));
  }, 200);


  const closeModal = () => {
    setModalData((prev) => ({
      ...prev,
      state: false,
      type: "",
      data: initialValues.data,
    }));
    setAvailDates([]);
    setAvailTimes([]);
  };
  function flattenArrayOfArraysOrKeepArray(arrayOrArrayOfArrays) {
    if (Array.isArray(arrayOrArrayOfArrays[0])) {
      return arrayOrArrayOfArrays.reduce((result, currentArray) => {
        return result.concat(currentArray);
      }, []);
    } else {
      return arrayOrArrayOfArrays;
    }
  }
  const [modalData, setModalData] = useState(initialValues);

  const setOpen = () => {
    setModalData((prev) => ({
      ...prev,
      state: true,
      type: "add",
      data: initialValues.data,
    }));
  };

  const [addPatientModal, setaddPatientModal] = useState(false);

  const editOpen = async (id, type) => {
    let headers = dispatch(getHeaders());
    let url = `${process.env.REACT_APP_API_URL}/api/therapist/schedule/availibility/session/${id}`;
    if (type) {
      url = url + `?type=${type}`;
    }
    const res = await axios.get(url, {
      headers,
    });

    let a = appointment.list.find((a) => a.id === id);
    console.log(a);
    let date = moment(res.data.data.date).format("YYYY-MM-DD");
    // getAvailableDates(a.therapist_id);
    getAvailableTimes(
      a.therapist_id,
      date,
      a.type || a.session_type,
      a.therapist_id ? "THERAPIST" : "ALL"
    );
    setModalData((prev) => ({
      ...prev,
      editId: id,
      state: true,
      type: "edit",
      data: a,
      apiData: res.data.data,
    }));
  };

  const editClose = () => {
    setModalData((prev) => ({
      ...initialValues,
    }));
    setAvailDates([]);
    setAvailTimes([]);
  };

  const [availDates, setAvailDates] = useState([]);

  const getAvailableDates = (id) => {
    axios
      .get(`${API}/api/therapist/appointment/get-appointment-dates/${id}`, {
        headers,
      })
      .then((res) => {
        setAvailDates(res.data.data || []);
      });
  };

  const [availTimes, setAvailTimes] = useState([]);

  const getAvailableTimes = (therapist_id, date, type, query) => {
    axios
      .post(
        `${API}/api/therapist/appointment/get-appointment-time-slots`,
        {
          therapist_id: therapist_id,
          date: [date],
          appointment_type: type,
          query_type: query,
        },
        { headers }
      )
      .then((res) => {
        setAvailTimes(res.data.data || []);
      });
  };

  const [rangeDates, setRangeDates] = useState({
    start: dateMath.startOf(new Date(), "week"),
    end: dateMath.endOf(new Date(), "week"),
  });

  const dispatch = useDispatch();
  const {
    therapistAppointment: appointment,
    exception: exception,
    location: location,
    patient: patient,
    Therapist: doctor,
    department: department,
  } = useSelector((state) => state);

  const cancelButtonRef = useRef(null);
  const [headers, setHeaders] = useState("");
  const [showAvailableSlots, setShowAvailableSlots] = useState(false);


  useEffect(() => {
    dispatch(getAppointment());
    dispatch(getAvailableSlots());
    dispatch(getAllTherapists());
    dispatch(getAllLocations());
    dispatch(getAllPatients());
    dispatch(getAllDepartments());
    setHeaders(dispatch(getHeaders()));
  }, []);

  useEffect(() => {
    dispatch(getAllPatients());
  }, [addPatientModal]);

  const { components, defaultDate, views, min, max } = useMemo(() => {
    let today = new Date();
    return {
      components: {
        timeSlotWrapper: ColoredDateCellWrapper,
      },
      defaultDate: new Date(),
      views: [Views.MONTH, Views.WEEK, Views.DAY, Views.AGENDA],
      min: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 6),
      max: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 22),
    };
  }, []);

  const eventPropGetter = useCallback((event, start, end, isSelected) => {
    return {
      ...(event.park === true && {
        style: {
          backgroundColor: "red",
          color: "white",
          border: "2px solid green",
        },
      }),
      ...(event.park === true &&
        event.type === "EVALUATION" && {
        style: {
          backgroundColor: "red",
          color: "white",
          border: "2px solid green",
        },
      }),
      ...(event.park === true &&
        event.type === "PROGRAM_APPOINTMENT" && {
        style: {
          backgroundColor: "red",
          color: "white",
          border: "2px solid green",
        },
      }),
      ...(event.type === "EVALUATION" &&
        event.park !== true && {
        style: {
          backgroundColor: "orange",
          color: "white",
          border: "2px solid green",
        },
      }),

      // ...((event.status !== "CONFIRMED" || event.isFollowUp) && {
      //   backgroundColor: "orange",
      //   color: "black",
      //   border: "2px solid orange",
      // }),
      ...(event.type === "PROGRAM_APPOINTMENT" &&
        event.park !== true && {
        style: {
          backgroundColor: "green",
          color: "white",
          border: "2px solid green",
        },
      }),
      // ...((event.status !== "CONFIRMED" || !event.isFollowUp) && {
      //   backgroundColor: "green",
      //   color: "white",
      //   border: "2px solid orange",
      // }),
      ...(event.isBackground && {
        style: {
          backgroundColor: "",
          color: "",
        },
      }),
      ...(event.isBackground && {
        style: {
          backgroundColor: "",
          color: "",
        },
      }),
    };
  }, []);

  const selectStatus = (e) => {
    console.log(e);
    console.log(filterLoc);
    if (e.status === "ONLINE") {
      const _enable = location.list.find((l) => l.name.trim() === "E-nable");
      console.log(_enable);
      setFilterLoc((pre) => ({
        id: _enable.id,
        name: _enable.name,
      }));
      console.log(filterLoc);
    }
    dispatch(
      getAvailableSlots(rangeDates.start, rangeDates.end, selected.id, e.status)
    );
    setStatus(e);
  };
  const selectType = (e) => {
    let start = dateMath.startOf(new Date(), "month");
    let end = dateMath.endOf(new Date(), "month");
    let therapist_id = null;
    let postURl = "search/booked/evaluation";
    if (e.id === 0 || e.id === 1) {
      dispatch(getAppointment());
    } else if (e.id === 2) {
      dispatch(getAppointment(start, end, therapist_id, postURl));
    }
    setProgram(e);
  };

  const selectLocation = (e) => {
    console.log(e);
    setFilterLoc(e);
  };
  const selectDepartment = (e) => {
    setDepartment(e);
  };

  const selectDoctor = (e) => {
    // console.log('on change', e);
    setSelected(e);
    if (e.id !== 0) {
      // make API call with doctorID
      dispatch(getAppointmentByDoctor(rangeDates.start, rangeDates.end, e.id));
      dispatch(
        getAvailableSlots(rangeDates.start, rangeDates.end, e.id, status.status)
      );
      setShowAvailableSlots(true);
    } else {
      // make API call with no doctorID
      dispatch(getAppointment(rangeDates.start, rangeDates.end));
      setShowAvailableSlots(false);
    }
  };

  const selectPatient = (e) => {
    setSelectedPatient({
      id: e.id,
      name: e.firstName + " " + e.lastName,
    });
  };

  // const [docenable, setdocenable] = useState(true); // for enabling and disabling data and time
  // const [timeenable, settimeenable] = useState(true); // for enabling and disabling data and time
  const [query, setQuery] = useState("");
  const [patientquery, setPatientQuery] = useState("");
  const filteredPeople =
    query === ""
      ? doctor.list
      : doctor.list.filter((person) => {
        return (
          person.first_name.toLowerCase().includes(query.toLowerCase()) ||
          person.last_name.toLowerCase().includes(query.toLowerCase())
        );
      });


  return (
    <div className="flex  flex-col" style={{ height: "85vh" }}>
      <Breadcrumb pages={pages} />
      <header className="flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6">
        <h1 className="text-lg font-semibold text-gray-900">Appointments</h1>
        <div className="flex pl-2 items-center justify-start flex-row gap-2">
          <div className="w-full flex flex-row flex-wrap gap-4 items-center justify-end">
            <Listbox value={departments} onChange={selectDepartment}>
              {({ open }) => (
                <>
                  <div className="relative  ">
                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3  text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                      <span className="block truncate w-40 font-medium">
                        {departments.name}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <Listbox.Option
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-8 pr-4"
                            )
                          }
                          value={{
                            id: 0,
                            name: "Filter by Department",
                          }}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                Filter by Department
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                        {department.list.map((l, idx) => (
                          <Listbox.Option
                            key={idx}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-indigo-600"
                                  : "text-gray-900",
                                "relative cursor-default select-none py-2 pl-8 pr-4"
                              )
                            }
                            value={{
                              id: l.id,
                              name: l.name,
                            }}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "block truncate"
                                  )}
                                >
                                  {l.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-indigo-600",
                                      "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
            <Listbox value={status} onChange={selectStatus}>
              {({ open }) => (
                <>
                  {/* <Listbox.Label className="pl-6 pr-2 block text-sm font-medium text-gray-700">Select Doctor</Listbox.Label> */}
                  <div className="relative  ">
                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3  text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                      <span className="block truncate w-32 font-medium">
                        {status.status}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <Listbox.Option
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-8 pr-4"
                            )
                          }
                          value={{
                            id: 0,
                            status: "Filter by status",
                          }}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                Filter by status
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                        <Listbox.Option
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-8 pr-4"
                            )
                          }
                          value={{
                            id: 1,
                            status: "OFFLINE",
                          }}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                Offline
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                        <Listbox.Option
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-8 pr-4"
                            )
                          }
                          value={{
                            id: 2,
                            status: "ONLINE",
                          }}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                Online
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
            <Listbox value={Program} onChange={selectType}>
              {({ open }) => (
                <>
                  {/* <Listbox.Label className="pl-6 pr-2 block text-sm font-medium text-gray-700">Select Doctor</Listbox.Label> */}
                  <div className="relative">
                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3  text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                      <span className="block truncate w-32 font-medium">
                        {Program.status}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <Listbox.Option
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-8 pr-4"
                            )
                          }
                          value={{
                            id: 0,
                            status: "Filter by type",
                          }}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                Filter by Type
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                        <Listbox.Option
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-8 pr-4"
                            )
                          }
                          value={{
                            id: 1,
                            status: "Program",
                          }}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                Program
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                        <Listbox.Option
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-8 pr-4"
                            )
                          }
                          value={{
                            id: 2,
                            status: "Evaluation",
                          }}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                Evaluation
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
            <Listbox value={filterLoc} onChange={selectLocation}>
              {({ open }) => (
                <>
                  <div className="relative  ">
                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3  text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                      <span className="block truncate w-40 font-medium">
                        {filterLoc.name}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <Listbox.Option
                          className={({ active }) =>
                            classNames(
                              active
                                ? "text-white bg-indigo-600"
                                : "text-gray-900",
                              "relative cursor-default select-none py-2 pl-8 pr-4"
                            )
                          }
                          value={{
                            id: 0,
                            name: "Filter by location",
                          }}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                Filter by location
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? "text-white" : "text-indigo-600",
                                    "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                        {location.list.map((l, idx) => (
                          <Listbox.Option
                            key={idx}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-indigo-600"
                                  : "text-gray-900",
                                "relative cursor-default select-none py-2 pl-8 pr-4"
                              )
                            }
                            value={{
                              id: l.id,
                              name: l.name,
                            }}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "block truncate"
                                  )}
                                >
                                  {l.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-indigo-600",
                                      "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
            <Combobox as="div" value={selectedPatient}
              onChange={selectPatient}>
              <div className="relative">
                <Combobox.Input
                  className="relative w-full cursor-default font-semibold placeholder:font-semibold rounded-md border border-gray-300 bg-white py-2 pl-3  text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                  onChange={(event) => {
                    onFilterChange(event.target.value);
                  }}
                  placeholder="Filter by Patient"
                  displayValue={(person) => (person.name ? person.name : "")}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>
                {allPatients.data.length > 0 && (
                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {allPatients.data.map((person) => (
                      <Combobox.Option
                        key={person.id}
                        value={person}
                        className={({ active }) =>
                          classNames(
                            "relative cursor-default select-none py-2 pl-8 pr-4",
                            active
                              ? "bg-indigo-600 text-white"
                              : "text-gray-900"
                          )
                        }
                      >
                        {({ active, selected }) => (
                          <>
                            <span
                              className={classNames(
                                "block truncate",
                                selected && "font-semibold"
                              )}
                            >
                              {person.firstName} {person.lastName} -{person.patient_id}
                            </span>

                            {selected && (
                              <span
                                className={classNames(
                                  "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                  active ? "text-white" : "text-indigo-600"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            </Combobox>
            <Combobox as="div" value={selected} onChange={selectDoctor}>
              <div className="relative">
                <Combobox.Input
                  className="relative w-full cursor-default font-semibold placeholder:font-semibold rounded-md border border-gray-300 bg-white py-2 pl-3  text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                  onChange={(event) => setQuery(event.target.value)}
                  placeholder="Filter by Therapist"
                  displayValue={(person) =>
                    person.last_name
                      ? person.first_name + " " + person.last_name
                      : ""
                  }
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>

                {filteredPeople.length > 0 && (
                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredPeople.map((person) => (
                      <Combobox.Option
                        key={person.id}
                        value={person}
                        className={({ active }) =>
                          classNames(
                            "relative cursor-default select-none py-2 pl-8 pr-4",
                            active
                              ? "bg-indigo-600 text-white"
                              : "text-gray-900"
                          )
                        }
                      >
                        {({ active, selected }) => (
                          <>
                            <span
                              className={classNames(
                                "block truncate",
                                selected && "font-semibold"
                              )}
                            >
                              {person.first_name + " " + person.last_name}
                            </span>

                            {selected && (
                              <span
                                className={classNames(
                                  "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                  active ? "text-white" : "text-indigo-600"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            </Combobox>
            {/* <Combobox
              as="div"
              className={"w-64"}
              value={selectedPatient}
              onChange={selectPatient}
            >
              <div className="relative  ">
                <Combobox.Input
                  className="relative w-full cursor-default font-semibold placeholder:font-semibold rounded-md border border-gray-300 bg-white py-2 pl-3  text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                  onChange={(event) => setPatientQuery(event.target.value)}
                  placeholder="Filter by Patient"
                  displayValue={(person) => (person.name ? person.name : "")}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>

                {patient.list.length > 0 && (
                  <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {patient.list.map((person) => (
                      <Combobox.Option
                        key={person.id}
                        value={person}
                        className={({ active }) =>
                          classNames(
                            "relative cursor-default select-none py-2 pl-8 pr-4",
                            active
                              ? "bg-indigo-600 text-white"
                              : "text-gray-900"
                          )
                        }
                      >
                        {({ active, selected }) => (
                          <>
                            <span
                              className={classNames(
                                "block truncate",
                                selected && "font-semibold"
                              )}
                            >
                              {person.firstName +
                                " " +
                                person.lastName +
                                " - " +
                                person.patient_id}
                            </span>

                            {selected && (
                              <span
                                className={classNames(
                                  "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                  active ? "text-white" : "text-indigo-600"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            )}
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}
              </div>
            </Combobox> */}
          </div>
          <Link
            to="/add-therapist-appointment"
            type="button"
            className="ml-6 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Add Appointment
          </Link>
        </div>
      </header>
      <CalendarDiv className="my-2 min-h-[100%]">
        {/* {appointment.loading ? null : ( */}
        {/* // : appointment.error !== null ? null */}
        <Calendar
          components={components}
          defaultDate={defaultDate}
          events={
            appointment.loading
              ? []
              : appointment.error !== null
                ? []
                : appointment.list
                  .filter((a) => {
                    if (status.id !== 0) {
                      return a.therapist_appointment.type === status.status;
                    }
                    return true;
                  })
                  .filter((a) => {
                    if (departments.id !== 0) {
                      return a.department_id === departments.id;
                    }
                    return true;
                  })
                  .filter((a) => {
                    if (filterLoc.id !== 0) {
                      return (
                        a?.location_id === filterLoc.id ||
                        a?.location === filterLoc.name
                      );
                    }
                    return true;
                  })
                  .filter((a) => {
                    if (selectedPatient.id !== 0) {
                      return a?.Patient?.id
                        ? Number(a?.Patient?.id) === Number(selectedPatient.id)
                        : Number(a?.therapist_appointment?.patient_id) ===
                        Number(selectedPatient.id);
                    }
                    return true;
                  })
                  .map((a) => {
                    // console.log(a);
                    return {
                      id: a.id,
                      title: [
                        a.status === "CONFIRMED" ? (
                          a.payment_type === "FULL" ? (
                            <PaidOutlined fontSize="small" />
                          ) : (
                            <MoneyOffCsredOutlined fontSize="small" />
                          )
                        ) : null,
                        ` Dr. ${a.TherapistMaster?.first_name}-${a.therapist_appointment?.patient?.firstName
                          ? a.therapist_appointment?.patient?.firstName
                          : a?.EvaluationAppointments?.Patient?.firstName
                            ? a?.EvaluationAppointments?.Patient?.firstName
                            : a?.therapist_appointment?.patient?.firstName
                        }`,
                      ],
                      start: new Date(
                        `${moment(a.date).format("YYYY-MM-DD")}T${moment(
                          a?.start_time?.split("T")?.[1],
                          "HH:mm:ss"
                        ).format("HH:mm")}:00+05:30`
                      ),
                      end: new Date(
                        `${moment(a.date).format("YYYY-MM-DD")}T${moment(
                          a?.end_time?.split("T")?.[1],
                          "HH:mm:ss"
                        ).format("HH:mm")}:00+05:30`
                      ),
                      status: a.therapist_appointment?.status,
                      park: a.park,
                      type: a.therapist_appointment?.agenda_type
                        ? a.therapist_appointment?.agenda_type
                        : "EVALUATION",
                    };
                  })
          }
          eventPropGetter={eventPropGetter}
          backgroundEvents={
            !showAvailableSlots
              ? []
              : appointment.aLoading
                ? []
                : appointment.aError !== null
                  ? []
                  : appointment.availableList
                    .filter((a) => {
                      console.log(a);
                      if (filterLoc.id !== 0)
                        return (
                          a.location === filterLoc.name ||
                          a.location_id === filterLoc.id
                        );
                      return true;
                    })
                    .map((a) => ({
                      start: `${a.date}T${a.start_time}:00+05:30`,
                      end: `${a.date}T${a.end_time}:00+05:30`,
                      title: `Dr. ${a.therapist?.first_name} ${a.therapist?.last_name}`,
                      allDay: false,
                      isBackground: true,
                      therapistId: a.therapist_id,
                      date: a.date,
                      time: `${a.start_time}-${a.end_time}`,
                      therapist_appointment: a.therapist_appointment,
                    }))
          }
          min={min}
          max={max}
          localizer={localizer}
          showMultiDayTimes
          step={20}
          views={views}
          // dayLayoutAlgorithm="no-overlap"
          dayPropGetter={() => ({
            className: "text-xs leading-5 border-transparent",
          })}
          defaultView={Views.MONTH}
          drilldownView="agenda"
          onDoubleClickEvent={(event) => {
            console.log(event);
            editOpen(event.id, event.type);
          }}
          onRangeChange={async (range) => {
            let start, end;
            if ("start" in range) {
              start = range["start"];
              end = range["end"];
            } else {
              start = range[0];
              if (range.length > 1) {
                end = range[range.length - 1];
              } else {
                end = start;
              }
            }
            setRangeDates({ start, end });
            if (selected.id !== 0) {
              dispatch(getAppointmentByDoctor(start, end, selected.id));
              dispatch(
                getAvailableSlots(start, end, selected.id, status.status)
              );
              setShowAvailableSlots(true);
            } else {
              dispatch(getAppointment(start, end));
              setShowAvailableSlots(false);
            }
          }}
          showAllEvents
        />
        {/* )} */}
      </CalendarDiv>
      {/* // ----------------Update Appointment Modal--------------------- */}
      <Transition.Root
        show={modalData.type === "edit" && modalData.state}
        as={Fragment}
      >
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={editClose}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start ">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                      <div className="flex justify-between items-center">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Edit Appointment
                        </Dialog.Title>
                        <button
                          type="button"
                          onClick={async () => {
                            try {
                              const res = await axios.put(
                                `${process.env.REACT_APP_API_URL}/api/therapist/appointment/park-session/${modalData.data.id}`,
                                {},
                                {
                                  headers,
                                }
                              );
                              if (res) {
                                toast.success(res.data.message);
                                dispatch(getAppointment());
                                dispatch(getAvailableSlots());
                                dispatch(getAllTherapists());
                                dispatch(getAllLocations());
                                dispatch(getAllPatients());
                                dispatch(getAllDepartments());
                                setHeaders(dispatch(getHeaders()));
                                dispatch(getAppointment());
                                editClose();
                              }
                            } catch (error) {
                              toast.error(error.response.data.message);
                            }
                          }}
                          className="rounded-md border border-red-600 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-300 hover:text-black focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          {appointment.delLoading
                            ? "Cancelling..."
                            : "Cancel appointment"}
                        </button>
                      </div>
                      <div className="mt-2">
                        <Formik
                          onSubmit={async (values, initialValues) => {
                            let start = values.time.split("-")[0];
                            let end = values.time.split("-")[1];
                            let id = modalData.editId;
                            let a = appointment.list.find(
                              (a) => a.id === modalData.editId
                            );

                            const body = {
                              date: moment(values.date).format(),
                              start_time: start,
                              end_time: end,
                              therapist_id: values.therapist_id
                                ? Number(values.therapist_id)
                                : Number(
                                  flattenArrayOfArraysOrKeepArray(
                                    availTimes
                                  ).find((t) => t.end_time === end)
                                    .therapist_id
                                ),
                            };
                            try {
                              const res = await axios.put(
                                `${process.env.REACT_APP_API_URL
                                }/api/therapist/appointment/update-session/${id}?type=${a.EvaluationAppointments
                                  ? "EVALUATION"
                                  : "PROGRAM_APPOINTMENT"
                                }`,
                                body,
                                {
                                  headers,
                                }
                              );
                              if (res) {
                                toast.success(res.data.message);
                                editClose();
                                dispatch(getAppointment());
                              }
                            } catch (error) {
                              toast.error(error.response.data.message);
                            }
                          }}
                          initialValues={{
                            ...modalData.data,
                            type:
                              modalData.data?.therapist_appointment?.type ||
                              modalData.data?.session_type,
                            days: [],
                            therapistList: [],
                            date: new Date(
                              moment(modalData.apiData.date).format(
                                "YYYY-MM-DD"
                              )
                            ),
                            time: `${modalData.apiData.start_time}-${modalData.apiData.end_time}`,
                            dtime: `${moment(
                              modalData.apiData.start_time,
                              "HH:mm:ss"
                            ).format("hh:mm A")} - ${moment(
                              modalData.apiData.end_time,
                              "HH:mm:ss"
                            ).format("hh:mm A")}`,
                            book_type:
                              modalData.data.book_type === "CUSTOM"
                                ? true
                                : false,
                            locationId: modalData.data.location_id,
                            patientId:
                              modalData.data?.therapist_appointment?.patient
                                ?.id ||
                              modalData.data?.EvaluationAppointments
                                ?.patient_id,
                            cost:
                              modalData.data.cost || modalData.apiData.price,
                          }}
                        // validationSchema={UpdateAppointmentSchema}
                        >
                          {({
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            setValues,
                            initialValues,
                            values,
                            touched,
                            errors,
                          }) => (
                            <form
                              noValidate
                              onSubmit={handleSubmit}
                              className="space-y-8 divide-y divide-gray-200"
                            >
                              <div className="space-y-8 divide-y divide-gray-200">
                                <div>
                                  <div>
                                    <div className="sm:col-span-3">
                                      <label
                                        htmlFor="type"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Type
                                      </label>
                                      <div className="mt-1">
                                        <select
                                          disabled
                                          value={values.type}
                                          onChange={(e) => {
                                            handleChange(e);
                                            getAvailableTimes(
                                              values.therapist_id,
                                              values.date,
                                              e.target.value,
                                              values.therapist_id
                                                ? "THERAPIST"
                                                : "ALL"
                                            );
                                            setValues({
                                              ...values,
                                              type: e.target.value,
                                            });
                                          }}
                                          onBlur={handleBlur}
                                          name="type"
                                          id="type"
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        >
                                          <option value="OFFLINE">
                                            Offline
                                          </option>
                                          <option value="ONLINE">Online</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="dateSelect"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Search therapist by days
                                      </label>
                                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <Select
                                          isMulti
                                          value={values.days}
                                          name="dateSelect"
                                          options={[
                                            {
                                              label: "Sunday",
                                              value: "SUNDAY",
                                            },
                                            {
                                              label: "Monday",
                                              value: "MONDAY",
                                            },
                                            {
                                              label: "Tuesday",
                                              value: "TUESDAY",
                                            },
                                            {
                                              label: "Wednesday",
                                              value: "WEDNESDAY",
                                            },
                                            {
                                              label: "Thursday",
                                              value: "THURSDAY",
                                            },
                                            {
                                              label: "Friday",
                                              value: "FRIDAY",
                                            },
                                            {
                                              label: "Saturday",
                                              value: "SATURDAY",
                                            },
                                          ]}
                                          onChange={async (e) => {
                                            setValues({
                                              ...values,
                                              days: e,
                                              therapist_id: "",
                                            });
                                            const day = e.map((e) => e.value);
                                            setAvailTimes([]);
                                            const headers = dispatch(
                                              getHeaders()
                                            );
                                            const { data } = await axios.post(
                                              `${API}/api/program/patient-recommendation/get-department-therapist/${modalData.apiData.department_id}/${values.locationId}`,
                                              { day },
                                              {
                                                headers,
                                              }
                                            );

                                            setValues((prev) => ({
                                              ...prev,
                                              therapistList: data.data,
                                            }));
                                          }}
                                          classNamePrefix="select"
                                        />
                                      </div>
                                    </div>
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="doctorId"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Therapist
                                      </label>
                                      <div className="mt-1">
                                        {doctor.loading ? (
                                          <span className="text-sm text-indigo-700">
                                            Fetching therapists
                                          </span>
                                        ) : doctor.error !== null ? (
                                          <span className="text-sm text-red-700">
                                            {doctor.error}
                                          </span>
                                        ) : (
                                          <select
                                            id="therapist_id"
                                            name="therapist_id"
                                            disabled={
                                              values.therapistList?.length === 0
                                            }
                                            value={values.therapist_id}
                                            onChange={async (e) => {
                                              let thisdoctor = doctor.list.find(
                                                (d) =>
                                                  Number(d.id) ==
                                                  Number(e.target.value)
                                              );
                                              setValues((prev) => ({
                                                ...prev,
                                                date: "",
                                                therapist_id: e.target.value,
                                                cost: values.isFollowUp
                                                  ? thisdoctor?.is_follow_up_cost ||
                                                  0
                                                  : thisdoctor?.appointment_cost ||
                                                  0,
                                              }));
                                              handleChange(e);
                                              getAvailableDates(e.target.value);
                                            }}
                                            onBlur={handleBlur}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                          >
                                            <option value="">
                                              Select a therapist
                                            </option>
                                            {values.days?.length > 0
                                              ? values.therapistList.map(
                                                (d, idx) => (
                                                  <option
                                                    key={idx}
                                                    value={d.id}
                                                  >{`${d?.first_name} ${d?.last_name}`}</option>
                                                )
                                              )
                                              : doctor.list.map((d, idx) => (
                                                <option
                                                  key={idx}
                                                  value={d.id}
                                                >{`${d?.first_name} ${d?.last_name}`}</option>
                                              ))}
                                          </select>
                                        )}
                                      </div>
                                    </div>
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="doctorId"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Department
                                      </label>
                                      <div className="mt-1">
                                        {department.loading ? (
                                          <span className="text-sm text-indigo-700">
                                            Fetching Departments
                                          </span>
                                        ) : doctor.error !== null ? (
                                          <span className="text-sm text-red-700">
                                            {doctor.error}
                                          </span>
                                        ) : (
                                          <select
                                            id="department_id"
                                            name="department_id"
                                            disabled
                                            value={
                                              modalData.apiData.department_id
                                            }
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                          >
                                            {department.list.map((d, idx) => (
                                              <option key={idx} value={d.id}>
                                                {d?.name}
                                              </option>
                                            ))}
                                          </select>
                                        )}
                                      </div>
                                    </div>
                                    {errors.doctorId && touched.doctorId ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.doctorId}
                                      </p>
                                    ) : null}
                                    <div className="relative flex items-start mt-4">
                                      <div className="flex h-5 items-center ml-4">
                                        <input
                                          checked={values.book_type}
                                          onChange={(e) => {
                                            setValues({
                                              ...values,
                                              book_type: e.target.checked,
                                            });
                                            handleChange(e);
                                            if (!e.target.checked) {
                                              if (values.therapist_id) {
                                                getAvailableDates(
                                                  values.therapist_id
                                                );
                                                if (values.date) {
                                                  let date = moment(
                                                    values.date
                                                  ).format("YYYY-MM-DD");
                                                  getAvailableTimes(
                                                    parseInt(
                                                      values.therapist_id
                                                    ),
                                                    date,
                                                    values.type,
                                                    values.therapist_id
                                                      ? "THERAPIST"
                                                      : "ALL"
                                                  );
                                                }
                                              }
                                            }
                                          }}
                                          onBlur={handleBlur}
                                          name="book_type"
                                          type="checkbox"
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                      </div>

                                      <div className="ml-3 text-sm">
                                        <label
                                          htmlFor="book_type"
                                          className="font-medium text-gray-700"
                                        >
                                          Is custom
                                        </label>
                                      </div>
                                    </div>
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="date"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Date
                                      </label>
                                      <div className="mt-1">
                                        <RcCalendar
                                          name="date"
                                          value={values.date}
                                          minDate={new Date()}
                                          minDetail="month"
                                          onChange={(value, e) => {
                                            if (values.book_type) {
                                              setValues({
                                                ...values,
                                                date: value,
                                              });
                                              handleChange(e);
                                            } else {
                                              let date =
                                                moment(value).format(
                                                  "YYYY-MM-DD"
                                                );
                                              setValues({
                                                ...values,
                                                time: "",
                                                date: value,
                                              });
                                              handleChange(e);
                                              if (value) {
                                                getAvailableTimes(
                                                  values.therapist_id,
                                                  date,
                                                  values.type,
                                                  values.therapist_id
                                                    ? "THERAPIST"
                                                    : "ALL"
                                                );
                                              } else {
                                                setAvailTimes([]);
                                              }
                                            }
                                          }}
                                          tileDisabled={({
                                            activeStartDate,
                                            date,
                                            view,
                                          }) => {
                                            if (values.book_type) return false;
                                            else if (availDates.length === 0)
                                              return false;
                                            else {
                                              console.log(availDates);
                                              return !availDates.includes(
                                                moment(date)
                                                  .format("YYYY-MM-DD")
                                                  .toString()
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {errors.date && touched.date ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.date}
                                      </p>
                                    ) : null}
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="time"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Patient
                                      </label>
                                      <div className="mt-1">
                                        <select
                                          disabled
                                          name="patientId"
                                          value={values.patientId}
                                          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        >
                                          {!values.therapist_id && (
                                            <option value="">
                                              Select a date
                                            </option>
                                          )}
                                          {!values.date && (
                                            <option value="">
                                              Select a date first
                                            </option>
                                          )}
                                          {values.therapist_id &&
                                            values.date && (
                                              <option value="">
                                                Select a Patient
                                              </option>
                                            )}
                                          {patient.list.map((l, idx) => (
                                            <option key={idx} value={l.id}>
                                              {l.firstName + " " + l.lastName}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="time"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Location
                                      </label>
                                      <div className="mt-1">
                                        <select
                                          name="locationId"
                                          value={values.locationId}
                                          disabled
                                          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                        >
                                          {!values.therapist_id && (
                                            <option value="">
                                              Select a date
                                            </option>
                                          )}
                                          {!values.date && (
                                            <option value="">
                                              Select a date first
                                            </option>
                                          )}
                                          {values.therapist_id &&
                                            values.date && (
                                              <option value="">
                                                Select a Location
                                              </option>
                                            )}
                                          {location.list.map((l, idx) => (
                                            <option key={idx} value={l.id}>
                                              {l.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                    <div className="sm:col-span-3 mt-4">

                                      <label
                                        htmlFor="time"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Time&nbsp;
                                        {initialValues.therapist_id ===
                                          values.therapist_id &&
                                          moment(initialValues.date).format("DD/MM/YYYY") === moment(values.date).format("DD/MM/YYYY")
                                          ? initialValues.dtime
                                          : ""}
                                      </label>
                                      <div className="mt-1">
                                        {values.book_type ? (
                                          <div className="flex space-x-2">
                                            <input
                                              type="time"
                                              name="time_start_time"
                                              value={values.time.split("-")[0]}
                                              onChange={(e) => {
                                                let existingTime =
                                                  values.time.split("-");
                                                existingTime[0] =
                                                  e.target.value;
                                                setValues({
                                                  ...values,
                                                  time: existingTime.join("-"),
                                                });
                                              }}
                                              className="block w-full rounded-md border-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                            <input
                                              type="time"
                                              name="time_end_time"
                                              value={values.time.split("-")[1]}
                                              onChange={(e) => {
                                                let existingTime =
                                                  values.time.split("-");
                                                existingTime[1] =
                                                  e.target.value;
                                                setValues({
                                                  ...values,
                                                  time: existingTime.join("-"),
                                                });
                                              }}
                                              className="block w-full rounded-md border-gray-300 disabled:bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            />
                                          </div>
                                        ) : (
                                          <select
                                            name="time"
                                            value={values.time}
                                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                          >
                                            {!values.patientId && (
                                              <option value="">
                                                Select a Patient first
                                              </option>
                                            )}
                                            {!values.locationId && (
                                              <option value="">
                                                Select a Location first
                                              </option>
                                            )}
                                            {values.locationId &&
                                              values.patientId && (
                                                <option value="">
                                                  Select Your New Time
                                                </option>
                                              )}

                                            {flattenArrayOfArraysOrKeepArray(
                                              availTimes
                                            ).map((t, idx) => {
                                              const startTimeFormatted = moment(
                                                t.start_time,
                                                "HH:mm"
                                              ).format("hh:mm A");
                                              const endTimeFormatted = moment(
                                                t.end_time,
                                                "HH:mm"
                                              ).format("hh:mm A");
                                              const value = `${t.start_time}-${t.end_time}`;
                                              const text = `${startTimeFormatted} - ${endTimeFormatted} ${t.therapist_name
                                                ? " - " + t.therapist_name
                                                : ""
                                                } `;

                                              return (
                                                <option key={idx} value={value}>
                                                  {text}
                                                </option>
                                              );
                                            })}
                                          </select>
                                        )}
                                      </div>
                                    </div>
                                    {errors.time && touched.time ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.date}
                                      </p>
                                    ) : null}
                                    <div className="sm:col-span-3 mt-4">
                                      <label
                                        htmlFor="cost"
                                        className="block text-sm font-medium text-gray-700"
                                      >
                                        Cost
                                      </label>
                                      <div className="mt-1">
                                        <input
                                          type="number"
                                          name="cost"
                                          value={values.cost}
                                          disabled={true}
                                          className="block w-full rounded-md disabled:bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        ></input>
                                      </div>
                                    </div>
                                    {errors.cost && touched.cost ? (
                                      <p className="text-red-600 text-sm">
                                        {errors.cost}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="pt-5">
                                <div className="flex justify-end">
                                  {/* {!isValid && (
                                    <span className="font-light text-red-500 mr-2">
                                      Please complete the form.
                                    </span>
                                  )} */}
                                  <button
                                    type="button"
                                    onClick={editClose}
                                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    // disabled={!isValid}
                                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    {appointment.uLoading
                                      ? "Updating..."
                                      : "Update"}
                                  </button>
                                </div>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export function AddPatientModal({ addPatientModal, setaddPatientModal }) {
  const [rfshowModal, setrfShowModal] = useState(false);
  const dispatch = useDispatch();
  const initialValues = {
    firstName: "",
    lastName: "",
    fatherFirstName: "",
    fatherLastName: "",
    motherFirstName: "",
    motherLastName: "",
    guardiansFirstName: "",
    guardiansLastName: "",
    location_id: "",
    referral_id: "",
    phoneNo: "",
    dob: "",
    address: "",
  };
  const { list: referralList } = useSelector((state) => state.referral);
  const { list: locationList } = useSelector((state) => state.location);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllReferral());
    dispatch(getAllLocations());
  }, []);
  const addPatient = async (body) => {
    try {
      const headers = dispatch(getHeaders());
      const { data } = await axios.post(`${API}/api/patient`, body, {
        headers,
      });
      console.log(data.data);
      toast.success("Patient added successfully!");
      setaddPatientModal(false);
    } catch (error) { }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: patientsSchema,
      onSubmit: (values, action) => {
        const body = {
          ...values,
          referral_id: parseInt(values.referral_id),
          location_id: parseInt(values.location_id),
        };
        body.dob = moment(values.dob).format();
        let flags = 0;
        flags += body.fatherFirstName && body.fatherLastName ? 1 : 0;
        flags += body.motherFirstName && body.motherLastName ? 1 : 0;
        flags += body.guardiansFirstName && body.guardiansLastName ? 1 : 0;
        if (flags < 1) {
          toast.error("atleast two guardians info are required");
        } else {
          addPatient(body);
          action.resetForm();
        }
      },
    });
  // console.log(errors)

  return (
    <Transition.Root show={addPatientModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setaddPatientModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start ">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-center font-medium leading-6 text-gray-900"
                    >
                      Add Patient
                    </Dialog.Title>
                    <div className="mt-2">
                      <form
                        noValidate
                        onSubmit={handleSubmit}
                        className="space-y-8 divide-y divide-gray-200"
                      >
                        <div className="space-y-6 sm:space-y-5">
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="firstName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              First Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                id="firstName"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.firstName && touched.firstName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.firstName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="lastName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Last Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                id="lastName"
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.lastName && touched.lastName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.lastName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="fatherFirstName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Father First Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="fatherFirstName"
                                placeholder="Father First Name"
                                id="fatherFirstName"
                                value={values.fatherFirstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.fatherFirstName &&
                                touched.fatherFirstName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.fatherFirstName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="fatherLastName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Father Last Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="fatherLastName"
                                placeholder="Father Last Name"
                                id="fatherLastName"
                                value={values.fatherLastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.fatherLastName &&
                                touched.fatherLastName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.fatherLastName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="motherFirstName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Mother First Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="motherFirstName"
                                placeholder="mother First Name"
                                id="motherFirstName"
                                value={values.motherFirstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.motherFirstName &&
                                touched.motherFirstName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.motherFirstName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="motherLastName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Mother Last Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="motherLastName"
                                placeholder="Mother Last Name"
                                id="motherLastName"
                                value={values.motherLastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.motherLastName &&
                                touched.motherLastName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.motherLastName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="guardiansFirstName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Guardian First Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="guardiansFirstName"
                                placeholder="Guardian First Name"
                                id="guardiansFirstName"
                                value={values.guardiansFirstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.guardiansFirstName &&
                                touched.guardiansFirstName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.guardiansFirstName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="guardiansLastName"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Guardian Last Name
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="guardiansLastName"
                                id="guardiansLastName"
                                placeholder="Guardian Last Name"
                                value={values.guardiansLastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.guardiansLastName &&
                                touched.guardiansLastName ? (
                                <p className="text-red-600 text-sm">
                                  {errors.guardiansLastName}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="phone"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Phone
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="text"
                                name="phoneNo"
                                placeholder="Phone Number"
                                id="phone"
                                value={values.phoneNo}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.phoneNo && touched.phoneNo ? (
                                <p className="text-red-600 text-sm">
                                  {errors.phoneNo}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="dob"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Date of birth
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <input
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                type="date"
                                name="dob"
                                id="dob"
                                value={values.dob}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.dob && touched.dob ? (
                                <p className="text-red-600 text-sm">
                                  {errors.dob}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="dob"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Locations
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                              <select
                                id="location_id"
                                name="location_id"
                                value={values.location_id}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                              >
                                <option value="all">Select a Location</option>

                                {locationList.map((d, idx) => (
                                  <option key={idx} value={d.id}>
                                    {d.name}
                                  </option>
                                ))}
                              </select>
                              {errors.location_id && touched.location_id ? (
                                <p className="text-red-600 text-sm">
                                  {errors.location_id}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label
                              htmlFor="referral_id"
                              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                            >
                              Select a Referral
                            </label>
                            <div className="mt-1 grid grid-cols-2 sm:col-span-2 sm:mt-0">
                              <div className="mt-1 flex  sm:col-span-2 sm:mt-0">
                                <select
                                  id="referral_id"
                                  name="referral_id"
                                  value={values.referral_id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                                >
                                  <option value="all">Select a Referral</option>

                                  {referralList.map((d, idx) => (
                                    <option key={idx} value={d.id}>
                                      {d.name}
                                    </option>
                                  ))}
                                </select>
                                <button
                                  type="button"
                                  onClick={() => {
                                    setrfShowModal(true);
                                  }}
                                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 items-center "
                                >
                                  <AiOutlinePlus size={23} />
                                  <AddRefferalModal
                                    showModal={rfshowModal}
                                    setShowModal={setrfShowModal}
                                  />
                                </button>
                              </div>
                              {errors.referral_id && touched.referral_id ? (
                                <p className="text-red-600 text-sm">
                                  {errors.referral_id}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                          <label
                            htmlFor="address"
                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                          >
                            Address
                          </label>
                          <div className="mt-1 sm:col-span-2 sm:mt-0">
                            <textarea
                              className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                              type="text"
                              name="address"
                              id="address"
                              placeholder="Address"
                              value={values.address}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.address && touched.address ? (
                              <p className="text-red-600 text-sm">
                                {errors.address}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              type="submit"
                              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
