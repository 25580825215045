import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { getHeaders } from "../../../redux/auth/actions";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { getAllAgeGroups } from "../../../redux/AgeGroupMaster/actions";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-hot-toast";
import { getAllGoals } from "../../../redux/GoalMaster/actions";
import Modal from "../../../components/Modal";
import ReactMarkdown from "react-markdown";
import { get } from "react-hook-form";
const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    purchase_order_id: "",
    storage_location_id: "",
    quantity: "",
    item_id: "",
  },
};
const api = process.env.REACT_APP_API_URL;
const AddPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, month } = useParams();
  const initialValues = {
    enrollment_id: id,
    month: month,
    department_id: "",
    plan_weekly_data: [],
  };
  const [modal, setModal] = useState(initialModalState);
  const [addActivity, setAddActivity] = useState(initialModalState);
  const [departList, setDepartList] = useState({
    loading: true,
    data: [],
  });
  const [goalList, setGoalList] = useState({
    loading: true,
    data: [],
  });
  const [planData, setPlanData] = useState({
    loading: true,
    data: {},
  });

  const [actiList, setActiList] = useState({});
  const [domainList, setDomainList] = useState({});
  const [moduleList, setModuleList] = useState({
    loading: false,
    data: [],
  });
  const [Valid, setIsValid] = useState(true);
  const [formState, setFormState] = useState(initialValues);
  const [inputList, setInputList] = useState([
    {
      week_number: "",
      goal_master_id: "",
      activity_id: [],
      module_id: "",
      goal_master: {},
      act_master: "",
      mod_master: {},
    },
  ]);

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        week_number: "",
        goal_master_id: "",
        activity_id: [],
        module_id: "",
        goal_master: {},
        act_master: "",
        mod_master: {},
      },
    ]);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    let list = [...inputList];
    list[index][name] = Number(value);
    setInputList(list);
  };
  const selectHandleInputChange = (selectedOption, index, field, bool) => {
    let list = [...inputList];

    switch (field) {
      case "goal":
        list[index]["goal_master"] = selectedOption;
        list[index]["goal_master_id"] = selectedOption.value;
        if (bool === true) {
          list[index]["mod_master"] = {};
          list[index]["module_id"] = "";
          list[index]["dom_master"] = {};
          list[index]["domain_id"] = "";
        }
        break;
      case "module":
        list[index]["mod_master"] = selectedOption;
        list[index]["module_id"] = selectedOption.value;
        if (bool === true) {
          list[index]["dom_master"] = [];
          list[index]["domain_id"] = "";
        }

        fetchActivityByDomain(selectedOption.value, index);
        break;
      case "domain":
        list[index]["dom_master"] = selectedOption;
        list[index]["domain_id"] = selectedOption.map((item) => item.value);
        getAllActivities(
          selectedOption.map((item) => item.value),
          index
        );
        break;
      default:
        throw new Error("Invalid input field provided");
    }

    setInputList(list);
  };
  const handleRemoveClick = (index) => {
    let list = inputList;
    const finalList = inputList.filter((ele, i) => i !== index);
    setInputList([...finalList]);
  };

  async function getDepartments() {
    setDepartList({
      loading: true,
      data: [],
    });
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.get(
        `${api}/api/enrollment/program-departments/${id}`,
        {
          headers: headers,
        }
      );
      if (res.data) {
        setDepartList({
          loading: false,
          data: res.data.data,
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  const getAllModules = async () => {
    const headers = await dispatch(getHeaders());
    setModuleList((prev) => ({ ...prev, loading: true }));
    try {
      const res = await axios.get(`${api}/api/module-master`, {
        headers: headers,
      });
      const { data, status } = res;
      if (status === 200) {
        setModuleList((prev) => ({
          ...prev,
          data: data.data,
          loading: false,
        }));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  async function getAllActivities(title, index) {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.post(
        `${api}/api/activity/get-activity-by-title`,

        { domain_module_title: title },
        {
          headers: headers,
        }
      );
      if (res.data) {
        setActiList((prev) => ({ ...prev, [index]: res.data.data }));
      }
    } catch (error) {
      toast.error("No activity in this department");
    }
  }
  const fetchActivityByDomain = async (mod_id, index) => {
    const headers = await dispatch(getHeaders());
    if (id !== undefined) {
      setDomainList({
        ...domainList,
        loading: true,
      });
      const res = await axios.get(
        `${api}/api/activity/desc-module-wise/${mod_id}`,
        {
          headers: headers,
        }
      );
      const { data } = res;
      setDomainList((prev) => ({
        ...prev,
        [index]: data.data,
      }));
    }
  };

  async function getAllGoals(patientId) {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.get(
        `${api}/api/goal/patient-wise/${patientId}`,

        {
          headers: headers,
        }
      );
      if (res.data) {
        setGoalList({
          loading: false,
          data: res.data.data,
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  useEffect(() => {
    getAllGoals(id);
  }, [id]);

  useEffect(() => {
    getDepartments();
    getPlanById();
    getAllModules();
    document.title = "New Horizon | add-Plan";
  }, []);

  function findElementsByMonthAndDepartment(arr, month, department_id) {
    const matchingElements = arr.filter((element) => {
      return (
        element.month === Number(month) &&
        element.department_id === Number(department_id)
      );
    });

    return matchingElements.length && matchingElements;
  }
  function convertArray(array) {
    return array.map((item) => ({ label: item.title, value: item.id }));
  }
  function gconvertArray(array) {
    return array.map((item) => ({ label: item.name, value: item.id }));
  }
  const [sug, setSUg] = useState("");
  const filteredList = inputList.map((item) => {
    const {
      mod_master,
      act_master,
      goal_master,
      domain_id,
      dom_master,
      ...rest
    } = item;
    return rest;
  });
  async function getPlanById() {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.get(`${api}/api/enrollment/plan-view/${id}`, {
        headers: headers,
      });

      setPlanData({
        loading: false,
        data: res.data.data,
      });


    } catch (err) {
    } finally {
      setPlanData(prev => (
        {
          ...prev,
          loading: false,
        }
      ));
      const pdetails = await axios.get(
        `${api}/api/enrollment/patient/enrollment/${id}`,
        {
          headers: headers,
        }
      );
      if (pdetails.status === 200) {
        const newFormState = {
          ...formState,
          enrollment_id: id,
          suggestion: sug,
          child_name:
            pdetails.data.data.firstName + " " + pdetails.data.data.lastName,
          child_age: pdetails.data.data.age,
        };
        setFormState((prev) => ({
          ...prev,
          ...newFormState,
        }));
        // console.log(pdetails.data.data)


        if (newFormState.age_group_id) {
          getAllActivities(newFormState.age_group_id);
        }
      }
    }
  }

  async function createPlan(body, action) {
    const headers = await dispatch(getHeaders());
    try {
      const res = await axios.post(`${api}/api/plan-builder`, body, {
        headers: headers,
      });
      if (res.data) {
        toast.success("Plan created successfully");
        return true;
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      onSubmit: async (values, action) => {
        // TODO: Optimize code here
        const data = {
          month: Number(values.month),
          suggestion: values.suggestion,
          department_id: Number(values.department_id),
          enrollment_id: Number(values.enrollment_id),
          plan_weekly_data: filteredList,
        };
        await createPlan(data, action);

        navigate(`/therapist-enrolment/plan-view/${id}/${values.month}`);
      },
    });
  useEffect(() => {
    if (values.month !== "" && values.department_id !== "") {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }, [values.month, values.department_id]);
  useEffect(() => {
    if (values.month && values.department_id) {
      if (planData?.data?.Plan_department_master?.length) {
        const filteredData = planData?.data?.Plan_department_master?.filter(
          (item) => {
            return (
              item.month === Number(values.month) &&
              item.department_id === Number(values.department_id)
            );
          }
        );

        if (filteredData["0"]) {
          setFormState((prev) => ({
            ...prev,
            suggestion: filteredData["0"].suggestion,
          }));
          const data = filteredData["0"]?.Plan_builder_weekly_master?.map(
            (item, index) => {
              fetchActivityByDomain(item.module_id, index);
              getAllActivities(
                item?.Plan_builder_weekly_activity?.[0]?.activity
                  ?.ActivityDomainMaster.domain_module_title,
                index
              );
              return {
                week_number: item.week_number,
                activity_id: item.Plan_builder_weekly_activity.map(
                  (a) => a.activity_id
                ),
                module_id: item.module_id,
                goal_master_id: item.goal_master_id,
                goal_master: {
                  label: item.goal_master.name,
                  value: item.goal_master.id,
                },

                mod_master: {
                  label: item?.moduleMaster?.name,
                  value: item?.moduleMaster?.id,
                },
                dom_master: {
                  label:
                    item?.Plan_builder_weekly_activity?.[0]?.activity
                      ?.ActivityDomainMaster.domain_module_title,
                  value:
                    item?.Plan_builder_weekly_activity?.[0]?.activity
                      ?.ActivityDomainMaster.domain_module_title,
                },
              };
            }
          );
          setInputList([...data]);
        } else {
          setInputList([
            {
              week_number: "",
              goal_master_id: "",
              activity_id: [],
              domain_id: [],
              goal_master: "",
              act_master: "",
              mod_master: "",
              dom_master: "",
            },
          ]);
        }
      }
    }
  }, [values.month, values.department_id]);
  const InfoModal = () => {
    const { type, state, data, edit_id } = modal;
    const [infoData, setInfoData] = useState({
      loading: true,
      data: [],
    });
    useEffect(() => {
      fetchActivity(inputList[edit_id]?.activity_id);
    }, [edit_id]);
    const fetchActivity = async (id) => {
      if (id !== undefined) {
        setInfoData({
          ...infoData,
          loading: true,
        });
        const res = await axios.post(
          `${api}/api/activity/desc-id-wise/get`,
          { activity_desc_id: id },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const { data } = res;
        setInfoData({
          ...infoData,
          data: data.data,
          loading: false,
        });
      }
    };

    return (
      <Modal
        size="max-w-5xl"
        title={`Plan View`}
        open={state}
        setOpen={() =>
          setModal((prev) => ({ ...prev, state: false, edit_id: "" }))
        }
      >
        {infoData.loading ? (
          <FallingLinesLoader />
        ) : (
          <div className="grid grid-cols-1 gap-4">
            {infoData?.data?.map((item) => (
              <div className="p-4 bg-white rounded-lg shadow-md border-t border-gray-200">
                <div className="flex items-center mb-2 border-b border-gray-300 pb-2">
                  <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                    Activity:
                  </h1>
                  <p className="text-md font-semibold text-gray-900 text-left">
                    {item?.title}
                  </p>
                </div>
                <div className="flex items-center mb-2 border-b border-gray-300 pb-2">
                  <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                    Age Group:
                  </h1>
                  <p className="text-md font-semibold text-gray-900 text-left">
                    {item?.ActivityDomainMaster?.AgeGroupMaster?.name}
                  </p>
                </div>
                <div className="flex items-center mb-2 border-b border-gray-300 pb-2">
                  <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                    Module Name:
                  </h1>
                  <p className="text-md font-semibold text-gray-900 text-left">
                    {item?.ActivityDomainMaster?.ModuleMaster?.name}
                  </p>
                </div>
                <div className="flex items-center mb-2 border-b border-gray-300 pb-2">
                  <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                    Desc:
                  </h1>
                  <p className="text-md font-semibold ml-4 text-gray-900 text-left">
                    {item?.description}
                  </p>
                </div>
                <div className="flex items-start mb-2 border-b border-gray-300 pb-2">
                  <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                    Current Concern:
                  </h1>
                  <div className="text-md font-semibold text-gray-900 text-left">
                    {item?.ActivityDomainMaster?.concern_targets?.map(
                      (item) => (
                        <p key={item}>{item}</p>
                      )
                    )}
                  </div>
                </div>
                <div className="flex items-start mb-2 border-b border-gray-300 pb-2">
                  <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                    Material required:
                  </h1>
                  <div className="text-md font-semibold text-gray-900 text-left">
                    {item?.ActivityDomainMaster?.material_required?.map(
                      (item) => (
                        <p key={item}>{item}</p>
                      )
                    )}
                  </div>
                </div>
                <div className="flex items-start mb-2">
                  <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                    Milestone:
                  </h1>
                  <div className="text-md font-semibold text-gray-900 text-left">
                    {item?.ActivityDomainMaster?.milestones?.map((item) => (
                      <p key={item}>{item}</p>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Modal>
    );
  };
  const handleCheckboxChange = (event, index) => {
    const { name, value, checked } = event.target;
    let list = [...inputList];

    switch (name) {
      case "activity_id":
        list[index].activity_id = checked
          ? [...list[index].activity_id, Number(value)]
          : list[index].activity_id.filter((id) => id !== Number(value));
        break;

      default:
        break;
    }

    setInputList(list);
  };
  const AddActivityModal = () => {
    const { type, state, index, data, edit_id } = addActivity;

    return (
      <Modal
        size="max-w-3xl"
        title={`Select Activity`}
        open={state}
        setOpen={() =>
          setAddActivity((prev) => ({
            ...prev,
            state: false,
            edit_id: "",
            index: "",
          }))
        }
      >
        <div className="grid grid-cols-1 gap-4">
          {actiList?.[index]?.map((item, idx) => (
            <div
              key={idx}
              className="p-4 bg-white rounded-lg shadow-md border-t border-gray-200"
            >
              <div className="flex items-center mb-2 border-b border-gray-300 pb-2">
                <input
                  type="checkbox"
                  name="activity_id"
                  value={item.id}
                  checked={inputList[index]?.activity_id?.includes(item.id)}
                  onChange={(event) => handleCheckboxChange(event, index)}
                  id={item.title}
                  className="mr-2"
                />

                <label htmlFor={item.title}>
                  <h1 className="text-xl font-bold mr-4 text-gray-900 text-left">
                    Activity:
                  </h1>
                </label>
                <p className="text-md font-semibold text-gray-900 text-left">
                  {item?.title}
                </p>
              </div>
              <div className="flex items-center mb-2 border-b border-gray-300 pb-2">
                <h1 className="text-xl self-start font-bold mr-4 text-gray-900 text-left">
                  Description:
                </h1>
                {/* <p className="text-md font-semibold text-gray-900 text-left your-custom-class">
                  {item?.description}
                </p> */}
                <p
                  className="your-custom-class"
                  dangerouslySetInnerHTML={{
                    __html: item?.description,
                  }}
                />
              </div>
              {/* please create a div and add a close button */}
            </div>
          ))}
        </div>
        <div className="flex justify-end mt-4">
          <button
            type="button"
            onClick={() => {
              setAddActivity((prev) => ({
                ...prev,
                state: false,
                index: index,
              }));
            }}
            className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            close
          </button>
        </div>
      </Modal>
    );
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div>
        {InfoModal()}
        {AddActivityModal()}
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex items-center rounded-md border mb-6 border-transparent bg-indigo-100 px-3 py-2 text-sm font-medium leading-4 text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Add Plan</h1>
        </div>
      </div>
      {goalList.loading && planData.loading ? (
        <FallingLinesLoader />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5 sm:pt-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-5 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="location_name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Child's Name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="child_name"
                      id="location-name"
                      autoComplete="off"
                      value={values.child_name}
                      placeholder="Enter Child's Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.location_name && touched.location_name ? (
                      <p className="text-red-700">{errors.location_name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="location_name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Child's Age
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      type="text"
                      name="child_age"
                      id="location-name"
                      autoComplete="off"
                      value={values.child_age}
                      placeholder="Enter Child's Age"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    {errors.location_name && touched.location_name ? (
                      <p className="text-red-700">{errors.location_name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="location_name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Month
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      name="month"
                      id="location-name"
                      autoComplete="off"
                      disabled={values.month}
                      value={values.month}
                      placeholder="Enter Child's Age"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    >
                      <option>select month</option>
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                    </select>
                    {errors.location_name && touched.location_name ? (
                      <p className="text-red-700">{errors.location_name}</p>
                    ) : null}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="location-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Department
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      name="department_id"
                      id="location-name"
                      autoComplete="off"
                      value={values.department_id}
                      placeholder="Enter Child's Age"
                      disabled={departList.loading}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    >
                      <option>select department</option>
                      {departList.loading == false &&
                        departList.data.map((item, index) => {
                          return (
                            <option key={index} value={item.department_id}>
                              {item.department_name}
                            </option>
                          );
                        })}
                    </select>
                    {errors.location_name && touched.location_name ? (
                      <p className="text-red-700">{errors.location_name}</p>
                    ) : null}
                  </div>
                </div>
                {Valid && (
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-5">
                    <label className="block text-sm font-normal  text-gray-700 sm:mt-px sm:pt-2">
                      Please Select Month and Department First
                    </label>
                  </div>
                )}

                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="location-name"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Suggestion Box
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <textarea
                      rows={4}
                      name="suggestion"
                      id="location-name"
                      autoComplete="off"
                      value={values.suggestion}
                      placeholder="Suggestions..."
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    ></textarea>
                  </div>
                </div>
                {inputList.map((x, index) => (
                  <>
                    <div
                      key={index}
                      className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5"
                    >
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="location-name"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Week No
                        </label>
                        <select
                          id="weekNo"
                          name="week_number"
                          value={x.week_number}
                          onChange={(e) => handleInputChange(e, index)}
                          onBlur={handleBlur}
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                        >
                          <option value="all">Select a Week No</option>

                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                        </select>
                      </div>
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="location-name"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Goal
                        </label>

                        <Select
                          options={goalList.data
                            .filter(
                              (g) =>
                                Number(g.department_id) ===
                                Number(values.department_id)
                            )
                            .map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))}
                          name="goal_master_id"
                          value={x.goal_master}
                          onChange={(selectedOption) =>
                            selectHandleInputChange(
                              selectedOption,
                              index,
                              "goal",
                              true
                            )
                          }
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                        />
                      </div>

                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="location-name"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Modules
                        </label>
                        <Select
                          options={gconvertArray(moduleList.data)}
                          name="module_id"
                          value={x.mod_master}
                          onChange={(selectedOption) =>
                            selectHandleInputChange(
                              selectedOption,
                              index,
                              "module",
                              true
                            )
                          }
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                        />
                      </div>
                      <div className="flex flex-col gap-2">
                        <label
                          htmlFor="location-name"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Domain
                        </label>
                        <Select
                          isMulti
                          options={domainList?.[index]?.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                          name="domain_id"
                          value={x.dom_master}
                          onChange={(selectedOption) =>
                            selectHandleInputChange(
                              selectedOption,
                              index,
                              "domain"
                            )
                          }
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-[1px] focus:border-indigo-500 sm:max-w-xs sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="w-[20%] flex items-center justify-center gap-2 sm:gap-4">
                      <button
                        type="button"
                        onClick={() => {
                          setAddActivity((prev) => ({
                            ...prev,
                            state: true,
                            edit_id: "",
                            index: index,
                          }));
                        }}
                        className="text-sm font-medium text-gray-700 px-4 py-2 border border-gray-300 m-2 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Choose Activity
                      </button>

                      <button
                        type="button"
                        className="ml-3 h-6 w-6 flex items-center justify-center rounded-full border border-transparent bg-indigo-600 p-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                        onClick={() =>
                          setModal((prev) => ({
                            ...prev,
                            state: true,
                            edit_id: index,
                          }))
                        }
                      >
                        i
                      </button>
                      <button
                        type="button"
                        className="ml-3 h-6 w-6 flex items-center justify-center rounded-full border border-transparent bg-indigo-600 p-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                        onClick={() => handleRemoveClick(index)}
                      >
                        -
                      </button>
                    </div>
                    {/* <div key={index} className="flex items-center ">
                      <div className="w-[90%] ">
                        <Select
                          options={actiList?.[index]?.map((item) => ({
                            label: item.title + " : - " + item.description,
                            value: item.id,
                          }))}
                          isMulti
                          name="activity_id"
                          value={x.act_master}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: "100%", // Set the width of the control to 100%
                            }),
                          }}
                          onChange={(selectedOption) =>
                            selectHandleInputChange(
                              selectedOption,
                              index,
                              "activity"
                            )
                          }
                          className="basic-single"
                        />
                      </div>

                      
                    </div> */}
                  </>
                ))}
                <button
                  type="button"
                  disabled={Valid}
                  className="w-1/3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm disabled:cursor-not-allowed disabled:bg-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-"
                  onClick={handleAddClick}
                >
                  Add more
                </button>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddPlan;
